import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {Node} from "../../../_models/index";

@Component({
	selector: "app-node-wrap",
	templateUrl: "./node-wrap.component.html",
	styleUrls: ["./node-wrap.component.less"]
})
export class NodeWrapComponent implements OnInit {
	@Input() QAList: Node;
	@Input() attributes: any;
	@Input() hasParent: boolean;
	@Output() deleteNodeEvent = new EventEmitter<string>(true);
	@Output() importTreeEvent = new EventEmitter<string>(true);
	@Output() copyBranch = new EventEmitter<string>(true);
	@Output() changesWereMade = new EventEmitter<string>(true);
	@Output() nodeCollapseEvent = new EventEmitter<string>(true);
	
	constructor() {}
	
	ngOnInit() {}
	
	public receiveMessage($event) {
		this.deleteNodeEvent.emit($event);
	}
	
	public receiveMessage1($event) {
		this.importTreeEvent.emit($event);
	}
	
	public receiveMessage2() {
		this.changesWereMade.emit();
	}
	
	public receiveMessageDuplicate($event) {
		this.copyBranch.emit($event);
	}
	
	public receiveMessageCollapse($event): void {
		this.nodeCollapseEvent.emit($event);
	}
	
}
