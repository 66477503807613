import {Component, OnInit} from "@angular/core";
import {AuthenticationService} from "../_services/index";

@Component({
	moduleId: module.id,
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.less"]
})
export class LoginComponent implements OnInit {
	model: any = {};
	loading = false;
	
	constructor(
		private authenticationService: AuthenticationService
	) {
	}
	
	ngOnInit() {
		this.authenticationService.logout();
	}
	
	
	login() {
		this.authenticationService.login(this.model.email, this.model.password);
	}
	
}
