import { Directive, ElementRef, Renderer2, HostListener, Input } from '@angular/core';
import { CategoriesListComponent } from "../categories/categories.component";

@Directive({
  selector: '[appActiveCategoryTabs]'
})
export class ActiveCategoryTabsDirective {
  
  @Input() categoryID: string;
  
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private qwe: CategoriesListComponent
  ) {
    let q: any = JSON.parse(localStorage.getItem('openedCategories'));
    if (!q) {
      q = null;
      localStorage.setItem('openedCategories', JSON.stringify(q));
    } else {
      setTimeout(() => {
        if (this.el.nativeElement.value === q) {
          this.renderer.addClass(this.el.nativeElement, 'open');
          this.qwe.showSubcategories(this.el.nativeElement.value);
          this.qwe.showQuestionnaire(this.el.nativeElement.value);
        }
      });
    }
  }
  
  @HostListener('click') clickEvent() {
    let e = this.el.nativeElement.value;
    let o = this.el.nativeElement.parentElement.children;
    localStorage.setItem('openedCategories', JSON.stringify(e));
    this.qwe.showSubcategories(e);
    this.qwe.showQuestionnaire(e);
    for (let g = 0; g < o.length; g++) {
      this.renderer.removeClass(o[g], 'open');
    }
    this.renderer.addClass(this.el.nativeElement, 'open');
  }

}
