import {Component, OnInit, Renderer2} from '@angular/core';
import { DecisionTreeService, SharedService } from '../../_services/index';

@Component({
  selector: 'app-decision-tree-table',
  templateUrl: './decision-tree-table.component.html',
  styleUrls: ['./decision-tree-table.component.less']
})
export class DecisionTreeTableComponent implements OnInit {
  
  lang: any = {active: localStorage.getItem('lang')};
  
  treeTableView: any;
  
  constructor(
    public _DecisionTreeService: DecisionTreeService,
    public _SharedService: SharedService,
    public renderer: Renderer2
  ) {
    this.getTree();
  }

  ngOnInit() {
    this._SharedService.changeLanguageEvent
      .subscribe((res) => {
        this.lang.active = res;
        this.attributesLangHandler();
      });
  }
  
  public attributesLangHandler(): void {
    this.treeTableView.forEach(obj => {
      obj['answers'].forEach(inner_obj => {
        inner_obj['attributes'].forEach(i => {
          if (this.lang.active === 'en') {
            i['description_to_show'] = i['description'];
          } else {
            for (let key of Object.keys(i['description_translation'])) {
              if (key.toLowerCase() === this.lang.active) {
                if (i['description_translation'][key] !== null) {
                  i['description_to_show'] = i['description_translation'][key];
                } else {
                  i['description_to_show'] = i['description'];
                }
              }
            }
          }
        });
      });
    });
  }
  
  public getTree(): void {
    this._DecisionTreeService.getDecisionTableTree()
      .subscribe(res => {
        this.treeTableView = res.result;
        this.attributesLangHandler();
      }, err => {
        console.log(err);
      });
  }
  
  public goTo(destination: any): void {
    let e = document.getElementById(destination);
    let allElements = document.querySelectorAll('tr');
    setTimeout(() => {
      e.scrollIntoView();
      for (let i = 0; i < allElements.length; i++) {
        this.renderer.removeClass(allElements[i], 'active');
      }
      this.renderer.addClass(e, 'active');
    });
  }
  
}
