<div class="outlet-wrapper user-attributes-component-wrapper">

  <div class="outlet-header">
    <span class="user_attr_title">{{ 'Settings.User_attributes.Page_title' | translate}}</span>
    <button class="main-action-btn no-icon-btn" (click)="addNewUserAttr()">
      <span>{{ 'Settings.User_attributes.Add_new_attr_btn' | translate}}</span>
    </button>
  </div>
  <div class="outlet-main-content">
    <div class="user-attr-table-wrapper">
      <table class="user-attr-table">
        <thead>
        <tr>
          <th class="name">{{ 'Settings.User_attributes.Name' | translate }}</th>
          <th class="edit"></th>
          <th class="delete"></th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of attributesList">
            <td>
              <div>
                {{i.description_to_show}}
                <span class="user_attribute_type">[ {{'Settings.User_attributes.' + i.attribute_type.name | translate}} ]</span>
              </div>
              <div class="attr-assignments">
                <div class="attribute_documents">
                  <div class="documents_wrapper">
                    <p>
                      <span class="documents-title" translate [translateParams]="{count: i.sub_categories.length}">
                        Settings.User_attributes.Documents_title
                      </span>
                      <span class="list-toggle" (click)="toggleCategories(i)" *ngIf="i.sub_categories.length">
                        <span *ngIf="!i.show_all_sub">{{ 'Settings.User_attributes.Show_all' | translate}}</span>
                        <span *ngIf="i.show_all_sub">{{ 'Settings.User_attributes.Hide_all' | translate}}</span>
                      </span>
                    </p>
                    <ul *ngIf="i.show_all_sub">
                      <li *ngFor="let q of i.sub_categories">
                        {{q.title_to_show}}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="attribute_questionnaires">
                  <div class="questionnaires_wrapper">
                    <p>
                      <span class="questionnaires-title" translate [translateParams]="{count: i.questions.length}">
                        Settings.User_attributes.Questionnaires_title
                      </span>
                      <span class="list-toggle" (click)="toggleQuestionnaires(i)" *ngIf="i.questions.length">
                        <span *ngIf="!i.show_all_questionnaires">{{ 'Settings.User_attributes.Show_all' | translate}}</span>
                        <span *ngIf="i.show_all_questionnaires">{{ 'Settings.User_attributes.Hide_all' | translate}}</span>
                      </span>
                    </p>
                    <ul *ngIf="i.show_all_questionnaires">
                      <li *ngFor="let q of i.questions">
                        {{q.question_to_show}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
            <td class="edit_btn" (click)="editUserAttr(i)">
              <i class="fas fa-edit"></i>
            </td>
            <td class="delete_btn" (click)="deleteAttribute(i)">
              <i class="fas fa-trash"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
