import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {EnvService} from "./env.service";

@Injectable()
export class MobileUsersService {

	USER: any;

	getUserData(): void {
		this.USER = JSON.parse(localStorage.getItem("currentUser"));
	}

	constructor(
		private env: EnvService,
		private http: HttpClient
	) {
	}

	public getPeriods(): Observable<any> {
		const url = `${this.env.apiBase}/period`;
		return this.http.get<Object>(url);
	}

	public getMobileUsers(is_active): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("is_active", is_active);
		return this.http.get<Object>(url, {params});
	}

	public getSingleMobileUser(data): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_id", data.id);
		return this.http.get<Object>(url, {params});
	}

	public updateSingleMobileUser(data): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.put<Object>(url, {
			"user_id": data.id,
			"name": data.name,
			"second_name": data.second_name,
			"email": data.email,
			"address": data.address,
			"additional_information": data.additional_info,
			"attributes_id": data.attribute_ids,
			"attributes_taxid": data.attributes_taxid,
			"attributes_build_taxid": data.attributes_build_taxid
		}, {params});
	}

	public toggleUserStatus(data): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.put<Object>(url, {
			"user_id": data.id,
			"is_active": data.is_active
		}, {params});
	}
	public deleteUser(data): Observable<any> {
		const url = `${this.env.apiBase}/user`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append('user_id', data.id);
		return this.http.delete<Object>(url, {params});
	}

	public getSubmittedUserDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "1")
		.append("user_id", data.user_id)
		.append("user_service_id", data.service_id);
		return this.http.get<Object>(url, {params});
	}

	public acceptSubmittedDocument(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("document_id", data.document_id)
		.append("status", data.status);
		return this.http.put<Object>(url, {}, {params});
	}

	public rejectSubmittedDocument(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("document_id", data.document_id)
		.append("status", data.status)
		.append("comment", data.comment);
		return this.http.put<Object>(url, {}, {params});
	}

	public downloadDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/download`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_owner_id", data.user_id)
		.append("language_code", data.language_code)
		.append("sub_category_id", data.sub_category_id)
		.append("period_id", data.period_id)
		.append("type", "1")
		.append("document_id", data.document_id)
		.append("user_service_id", data.service_id);

		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}

	public downloadTaxCalcAndDeclarations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/download`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("language_code", data.language_code)
		.append("user_owner_id", data.user_id)
		.append("type", data.type)
		.append("period_id", (!data.period_id) ? "" : data.period_id);

		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}

	public deleteDocumentFile(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document-item/${data.id}`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.delete<Object>(url, {params});
	}

	public deleteDocument(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("document_id", data.id);
		return this.http.delete<Object>(url, {params});
	}

	public postUserComment(data): Observable<any> {
		const url = `${this.env.apiBase}/user/communication`;
		let params = new HttpParams()
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, data, {params});
	}

	public getTaxIdUserData(data): Observable<any> {
		const url = `${this.env.apiBase}/taxid`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_id", data.id)
		.append("user_service_id", data.service_id);
		return this.http.get<Object>(url, {params});
	}

	public editUserTaxidData(data): Observable<any> {
		const url = `${this.env.apiBase}/taxid`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_service_id", data.service_id);
		return this.http.post<Object>(url, data, {
			params: params
		});
	}

	public printUserTaxidData(data): Observable<any> {
		const url = `${this.env.apiBase}/taxid/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("taxid_id", data.taxid_id);
		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}

	public getGmbhUserData(data): Observable<any> {
		const url = `${this.env.apiBase}/gmbh`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_id", data.id)
		.append("service", data.service);
		return this.http.get<Object>(url, {params});
	}

	public getSubmittedDeclarations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "2")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}

	public getSubmittedTaxCalculations(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "3")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}


	public postDocuments(data): Observable<any> {
		console.log(data);
		// const url = `${this.env.apiBase}/user/document`;
		const url = `${this.env.apiBase}/user/document/form-data`;
		let formData = new FormData();
		formData.append('period_id', data.period_id);
		formData.append('tags[]', data.tags);
		formData.append('sub_category_id', data.sub_category_id);
		formData.append('document_id', data.document_id);
		formData.append('description', data.description);
		formData.append('part_name', data.part_name);
		formData.append('type_document', data.type_document);
		data.files_array.forEach(o => {
			formData.append('photos_array[]', o);
		});

		this.getUserData();
		let params = new HttpParams()
		.append("user_owner_id", data.user_id)
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, formData, {params});
	}
	/*public postTaxCalcAndDeclarations(data): Observable<any> {
		console.log(data);
		// const url = `${this.env.apiBase}/user/document`;
		const url = `${this.env.apiBase}/user/document/form-data`;
		let formData = new FormData();
		formData.append('period_id', data.period_id);
		formData.append('tags[]', data.tags);
		formData.append('sub_category_id', data.sub_category_id);
		formData.append('document_id', data.document_id);
		formData.append('description', data.description);
		formData.append('part_name', data.part_name);
		formData.append('type_document', data.type_document);
		data.files_array.forEach(o => {
			formData.append('photos_array[]', o);
		});

		this.getUserData();
		let params = new HttpParams()
		.append("user_owner_id", data.user_id)
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, formData, {params});
	}*/
	/*public postOfficialConfDocs(data): Observable<any> {
		console.log(data);
		// const url = `${this.env.apiBase}/user/document`;
		const url = `${this.env.apiBase}/user/document/form-data`;
		let formData = new FormData();
		formData.append('period_id', data.period_id);
		formData.append('tags[]', data.tags);
		formData.append('sub_category_id', data.sub_category_id);
		formData.append('document_id', data.document_id);
		formData.append('description', data.description);
		formData.append('part_name', data.part_name);
		formData.append('type_document', data.type_document);
		data.files_array.forEach(o => {
			formData.append('photos_array[]', o);
		});
		this.getUserData();
		let params = new HttpParams()
		.append("user_owner_id", data.user_id)
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, formData, {params});
	}*/

	public getSubmittedPowerOfAttorneyDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "4")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}

	/*public postPowerOfAttorneyDocuments(data): Observable<any> {
		console.log(data);
		// const url = `${this.env.apiBase}/user/document`;
		const url = `${this.env.apiBase}/user/document/form-data`;
		let formData = new FormData();
		formData.append('period_id', data.period_id);
		formData.append('tags[]', data.tags);
		formData.append('sub_category_id', data.sub_category_id);
		formData.append('document_id', data.document_id);
		formData.append('description', data.description);
		formData.append('part_name', data.part_name);
		formData.append('type_document', data.type_document);
		data.files_array.forEach(o => {
			formData.append('photos_array[]', o);
		});
		this.getUserData();
		let params = new HttpParams()
		.append("user_owner_id", data.user_id)
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, formData, {params});
	}*/

	//getSubmittedTaxAdvisorAccountsDocuments
	public getSubmittedTaxAdvisorAccountsDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "5")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}

	public getOfficialConfDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("type", "6")
		.append("user_id", data.user_id);
		return this.http.get<Object>(url, {params});
	}

	/*public postTaxAdvisorAccountsDocuments(data): Observable<any> {
		console.log(data);
		const url = `${this.env.apiBase}/user/document`;
		this.getUserData();
		let params = new HttpParams()
		.append("user_owner_id", data.user_id)
		.append("userId", this.USER.user_id);
		return this.http.post<Object>(url, data, {params});
	}*/

	public sendMessage(data): Observable<any> {
		console.log(data);
		const url = `${this.env.apiBase}/operator/emails`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_ids", data.user_ids.toString())
		.append("subject", data.subject)
		.append("text", data.text);
		return this.http.post<Object>(url, {}, {params});
	}

	public downloadDefDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/download`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_owner_id", data.user_id)
		.append("language_code", data.language_code)
		.append("sub_category_id", data.sub_category_id)
		.append("period_id", data.period_id)
		.append("type", data.type)
		.append("document_id", data.document_id);

		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}

	public downloadDefPDFDocuments(data): Observable<any> {
		const url = `${this.env.apiBase}/user/document/pdf`;
		this.getUserData();
		let params = new HttpParams()
		.append("userId", this.USER.user_id)
		.append("user_id", data.user_id)
		.append("language_code", data.language_code)
		.append("sub_category_id", data.sub_category_id)
		.append("period_id", data.period_id)
		.append("type", data.type)
		.append("document_id", data.document_id);

		return this.http.get(url, {
			params: params,
			responseType: "arraybuffer"
		});
	}

}
