import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  
  public transform(array: any[], searchText: string, terms: string) {
    let filterKeys;
    if (terms !== undefined) {
      filterKeys = terms.split(',');
    } else {
      filterKeys = [];
    }
    if (searchText) {
      return array.filter(item => {
        const filter = filterKeys.length ? filterKeys : Object.keys(item);
        return filter.some(function (key) {
          let i = key.split('.');
          
          let q = (i.length > 1) ? item[i[0]][i[1]] : item[i[0]];
          
          if (q === undefined || q ===  null) {
            q = '';
          }
          return q.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
        });
      });
    } else {
      return array;
    }
  }
}
