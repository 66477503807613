import {Component, OnInit} from "@angular/core";
import {SettingsService, CategoryService, SharedService} from "../../../_services/index";
import {NgSelectComponent} from "@ng-select/ng-select";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
	selector: "app-create-user-attribute",
	templateUrl: "./create-user-attribute.component.html",
	styleUrls: ["./create-user-attribute.component.less"]
})
export class CreateUserAttributeComponent implements OnInit {
	
	attributeTypes = [
		{
			id: 0,
			name: "User_attribute_type_0"
		}, {
			id: 1,
			name: "User_attribute_type_1"
		}, {
			id: 2,
			name: "User_attribute_type_2"
		}, {
			id: 3,
			name: "User_attribute_type_3"
		}, {
			id: 4,
			name: "User_attribute_type_4"
		}, {
			id: 5,
			name: "User_attribute_type_5"
		}, {
			id: 6,
			name: "User_attribute_type_6"
		}
	];
	
	lang: any = {active: localStorage.getItem("lang")};
	objectKeys = Object.keys;
	questionnairesList: any[] = [];
	documentTypesList: any = [];
	
	userAttributeModel: any = {
		enTitle: "",
		selectedType: this.attributeTypes[0].id,
		selectedDocumentTypes: [],
		selectedQuestionnaires: []
	};
	titleTranslations: any[] = [];
	
	constructor(
		private _SettingsService: SettingsService,
		private _CategoriesService: CategoryService,
		private translate: TranslateService,
		private _sharedService: SharedService,
		private router: Router
	) {
		this.getRegisteredLanguages();
		this.getCategories();
	}
	
	ngOnInit() {
		this._sharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang.active = res;
			this.categoriesLangHandler();
			this.questionnairesLangHandler();
		});
	}
	
	getRegisteredLanguages(): void {
		this._SettingsService.getRegisteredLanguages()
		.subscribe(res => {
			for (let i = 0; i < res.languages.length; i++) {
				if (res.languages[i]["lang_code"] !== "EN") {
					let tempObj = Object.assign({}, {
						[res.languages[i]["lang_code"]]: {
							"title": ""
						}
					});
					this.titleTranslations.push(tempObj);
				}
			}
		}, err => {
			console.log(err);
		});
	}
	
	public categoriesLangHandler(): void {
		this.documentTypesList.forEach(i => {
			if (this.lang.active === "en") {
				i["title_to_show"] = i["title"];
				i["category_title_to_show"] = i["category_title"];
			} else {
				for (let key of Object.keys(i["title_translation"])) {
					if (key.toLowerCase() === this.lang.active) {
						if (i["title_translation"][key] !== null) {
							i["title_to_show"] = i["title_translation"][key];
						} else {
							i["title_to_show"] = i["title"];
						}
					}
				}
				for (let key of Object.keys(i["category_title_translations"])) {
					if (key.toLowerCase() === this.lang.active) {
						if (i["category_title_translations"][key] !== null) {
							i["category_title_to_show"] = i["category_title_translations"][key];
						} else {
							i["category_title_to_show"] = i["category_title"];
						}
					}
				}
			}
		});
		this.documentTypesList = [...this.documentTypesList];
	}
	
	public questionnairesLangHandler(): void {
		this.questionnairesList.forEach(i => {
			if (this.lang.active === "en") {
				i["question_to_show"] = i["question"];
				i["category_title_to_show"] = i["category_title"];
			} else {
				for (let key of Object.keys(i.question_translation)) {
					if (key.toLowerCase() === this.lang.active) {
						if (i.question_translation[key]) {
							i["question_to_show"] = i.question_translation[key];
						} else {
							i["question_to_show"] = i["question"];
						}
					}
				}
				
				for (let key of Object.keys(i.category_title_translations)) {
					if (key.toLowerCase() === this.lang.active) {
						if (i["category_title_translations"][key] !== null && i["category_title_translations"][key]) {
							i["category_title_to_show"] = i["category_title_translations"][key];
						} else {
							i["category_title_to_show"] = i["category_title"];
						}
					}
				}
			}
		});
		
		this.questionnairesList = [...this.questionnairesList];
	}
	
	public getCategories(): void {
		this._CategoriesService.getCategoriesAll()
		.subscribe(res => {
			this.documentTypesList = [];
			res.categories.forEach(obj => {
				if (obj.is_active) {
					obj.subCategories.forEach(child_obj => {
						child_obj["category_title"] = obj.title;
						child_obj["category_title_translations"] = obj.title_translation;
						if (child_obj.is_active) {
							this.documentTypesList.push(child_obj);
						}
					});
				}
			});
			this.categoriesLangHandler();
			this.getQuestionnaire(res.categories);
		}, err => {
			console.log(err);
		});
		
	}
	
	public getQuestionnaire(categories): void {
		this._CategoriesService.getQuestionnaires()
		.subscribe(res => {
			this.questionnairesList = res.result;
			for (let q = this.questionnairesList.length; q--;) {
				if (!this.questionnairesList[q]["category_id"]) {
					let index = this.questionnairesList.indexOf(this.questionnairesList[q]);
					if (index !== -1) {
						this.questionnairesList.splice(index, 1);
					}
				}
			}
			this.questionnairesList.sort((a, b) => {
				return a.category_id - b.category_id;
			});
			
			categories.forEach(obj => {
				if (obj.is_active) {
					this.questionnairesList.forEach(i => {
						if (i.category_id === obj.id) {
							let q = Object.assign({}, obj);
							i["category_title"] = q["title"];
							i["category_title_translations"] = q["title_translation"];
						}
					});
				}
			});
			this.questionnairesLangHandler();
		}, err => {
			console.log(err);
		});
	}
	
	public addNewAttribute(model, translations): void {
		let t = [];
		for (let i = 0; i < translations.length; i++) {
			for (const key of Object.keys(translations[i])) {
				t.push({
					"language_code": key,
					"description": translations[i][key].title
				});
			}
		}
		t.push({
			"language_code": "EN",
			"description": model.enTitle
		});
		
		let objToSent = {
			descriptions: t,
			type: model.selectedType,
			sub_categories_id: model.selectedDocumentTypes,
			forms_id: model.selectedQuestionnaires
		};
		
		console.log(objToSent);
		this._SettingsService.addNewAttr(objToSent)
		.subscribe(res => {
			this.router.navigate(["/settings/user-attributes"]);
		}, err => {
			console.log(err);
		});
	}
	
	closeSelect(select: NgSelectComponent) {
		select.close();
	}
	
	openSelect(select: NgSelectComponent) {
		select.open();
	}
	
	clearAllNew(documents) {
		let translated_msg;
		if (documents) {
			this.translate.get("Alert.Are_you_sure_question")
			.subscribe((res: Object) => {
				translated_msg = res;
				let q = confirm(translated_msg);
				if (q) {
					this.userAttributeModel.selectedDocumentTypes = [];
				}
			});
		} else {
			this.translate.get("Alert.Are_you_sure_question")
			.subscribe((res: Object) => {
				translated_msg = res;
				let q = confirm(translated_msg);
				if (q) {
					this.userAttributeModel.selectedQuestionnaires = [];
				}
			});
		}
	}
	
}
