<div class="container create-questionnaire-tpl">
  <div class="row">
    <div class="main-content col-12">
      <div class="top-bar">
        <div class="breadcrumbs">
        <span class="breadcrumb-item">
          <a [routerLink]="['/categories']">{{ 'Shared.Categories_breadcrumb' | translate }}</a>
        </span>
          <span class="breadcrumb-item">
          {{ 'Shared.Questionnaires_breadcrumb' | translate }}
        </span>
          <span class="breadcrumb-item">
          {{ 'Categories.Questionnaire.New_questionnaire_title' | translate }}
        </span>
        </div>
        <div class="controls">
          <button class="submit-form-btn no-icon-btn" form="createQuestionnaireForm">
            <span>{{ 'Shared.Save' | translate }}</span>
          </button>
          <button class="cancel-form-btn no-icon-btn" routerLink="/categories" type="button">
            <span>{{ 'Shared.Cancel' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="form-wrapper">

        <form id="createQuestionnaireForm" name="createQuestionnaireForm" class="createQuestionnaireForm col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" (ngSubmit)="f.form.valid && createQuestionnaire(questionnaireModel, translations)" #f="ngForm" novalidate>

          <div class="form-group required_section" [ngClass]="{ 'has-error': f.submitted && !title_EN.valid }">

            <div class="input-wrapper title">
              <label for="title">{{ 'Shared.Question' | translate }} (EN) <span class="required_asterisk">*</span></label>
              <textarea class="form-control" name="title" id="title" [(ngModel)]="questionnaireModel.title" #title_EN="ngModel" required></textarea>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !title_EN.valid">
              <p *ngIf="f.submitted && !title_EN.valid">{{'Shared.Field_is_required' | translate}}</p>
            </div>

            <!--<div class="input-wrapper description">
              <label for="description">{{ 'Shared.Description' | translate }} (EN)</label>
              <textarea class="form-control" name="description" id="description" [(ngModel)]="questionnaireModel.description" #description_EN="ngModel"></textarea>
            </div>-->
          </div>

          <div class="form-group" *ngFor="let q of translations; let i = index">
            <div *ngFor="let key of objectKeys(q)">
              <div class="input-wrapper title">
                <label for="{{key}}_title">{{ 'Shared.Question' | translate }} ({{key}})</label>
                <textarea class="form-control" name="{{key}}_title" id="{{key}}_title" [(ngModel)]="q[key].title" #title="ngModel"></textarea>
              </div>

              <!--<div class="input-wrapper description">
                <label for="{{key}}_descr">{{ 'Shared.Description' | translate }} ({{key}})</label>
                <textarea class="form-control" name="{{key}}_descr" id="{{key}}_descr" [(ngModel)]="q[key].descr" #description="ngModel"></textarea>
              </div>-->
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
