<div class="container">
  <div class="row">
    <div class="settings sidebar col-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
      <div class="settings-sidebar-header">
        <span class="settings-sidebar-title">{{ 'Settings.Sidebar.Title' | translate }}</span>
      </div>
      <div class="settings-list-wrapper">
        <ul class="settings-list">
          <li [routerLinkActive]="['active-link']" [routerLink]="['language']">{{ 'Settings.Sidebar.Language_management' | translate }}</li>
          <li [routerLinkActive]="['active-link']" [routerLink]="['user-attributes']">{{ 'Settings.Sidebar.User_attributes' | translate }}</li>
          <li [routerLinkActive]="['active-link']" [routerLink]="['faq']">{{ 'Settings.Sidebar.FAQ_sidebar_title' | translate }}</li>
          <li [routerLinkActive]="['active-link']" [routerLink]="['email-setup']">{{ 'Settings.Sidebar.Email_setup_sidebar_title' | translate }}</li>
        </ul>
      </div>
    </div>
    <div class="settings router-outlet main-content col-9 col-sm-12 col-md-9 col-lg-9 col-xl-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
