import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthenticationService} from "../../../_services/index";
import {TranslateService} from "@ngx-translate/core";
import {CategoriesListComponent} from "../../../categories/categories.component";
import {SharedService} from "../../../_services/shared.service";
import {Router, NavigationEnd} from "@angular/router";
import {EnvService} from "../../../_services/index";
import {Subscription} from "rxjs/Subscription"

@Component({
	providers: [CategoriesListComponent],
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.less"]
})
export class HeaderComponent implements OnInit, OnDestroy {
	lang: any = {active: localStorage.getItem("lang")};
	user: any;
	env: any;
	subscription: Subscription;
	
	constructor(
		private authenticationService: AuthenticationService,
		private translate: TranslateService,
		private _SharedService: SharedService,
		private router: Router,
		private _Env: EnvService
	) {
		this.lang["active"] = this.lang["active"] === null ? "ru" : this.lang["active"];
		this.user = JSON.parse(localStorage.getItem("currentUser"));
		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.user = JSON.parse(localStorage.getItem("currentUser"));
			}
		});
		this.env = this._Env;
	}
	
	ngOnInit() {
		this.subscription = this._SharedService.loggedOutObs$.subscribe(() => {
			this.user = null;
		})
	}
	
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	
	public logout(): void {
		this.authenticationService.logout();
	}
	
	public useLanguage(lang): void {
		this.lang.active = lang;
		this.translate.use(lang);
		this._SharedService.changeLanguageEvent.emit(lang);
	}
	
}
