import { Component, OnInit } from '@angular/core';
import { CategoryService, AlertService, SettingsService } from "../../_services/index";
import { Router, ActivatedRoute } from "@angular/router";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-add-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.less']
})
export class CreateCategoryComponent implements OnInit {
  
  imagePlaceholder: any = '/assets/images/placeholder.png';
  imageToUpload: any = null;
  livePreviewImg: File = null;
  uploadPhotoForm: FormGroup;
  
  objectKeys = Object.keys;
  categoryData: any = {};
  translations: any[] = [];
  t: any[] = [];
  d: any[] = [];
  
  constructor(
    private _categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private _alertService: AlertService,
    private _settingsService: SettingsService,
    private fb: FormBuilder
  ) {
    this.getRegisteredLanguages();
    this.photoForm();
  }

  ngOnInit() {}
  
  public photoForm(): void {
    this.uploadPhotoForm = this.fb.group({
      image: [null, [Validators.required]]
    });
  }
  
  public getRegisteredLanguages(): void {
    this._settingsService.getRegisteredLanguages()
      .subscribe(res => {
        for (let i = 0; i < res.languages.length; i++) {
          if (res.languages[i]['lang_code'] !== 'EN') {
            let tempObj = {
              [res.languages[i]['lang_code']] : {
                'title': '',
                'descr': ''
              }
            };
            this.translations.push(tempObj);
          }
        }
      }, err => {
        console.log(err);
      });
  }
  
  public handleImageInput(file: FileList) {
    this.imageToUpload = file;
    this.livePreviewImg = file.item(0);
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.categoryData.image_path = event.target.result;
    };
    reader.readAsDataURL(this.livePreviewImg);
  }
  
  public deletePhoto(): void {
    this.uploadPhotoForm.reset();
    this.categoryData.image_path = null;
    this.imageToUpload = false;
  }
  
  public createCategory(EN_data, translations): void {
    this.t = [];
    this.d = [];
    
    for (let i = 0; i < translations.length; i++) {
      for (const key of Object.keys(translations[i])) {
        this.t.push({
          'language_code': key,
          'translation': translations[i][key].title
        });
        this.d.push({
          'language_code': key,
          'translation': translations[i][key].descr
        });
      }
    }
  
    this.d.push({
      'language_code': 'EN',
      'translation': (EN_data.description) ? EN_data.description : ''
    });
  
    let objToSend = {
      title_EN: EN_data.title,
      title_translations: this.t,
      description_translations: this.d
    };
    
    this._categoryService.createNewCategory(objToSend)
      .subscribe(res => {
        console.log(res);
        if (this.imageToUpload) {
          this._categoryService.uploadPhoto(res.categories.id, this.imageToUpload[0])
            .subscribe(res1 => {
              this.router.navigate(['/categories']);
            }, err => {
              this._alertService.error('Alert.Opps_something_went_wrong');
            });
        }
        this.router.navigate(['/categories']);
        this._alertService.success('Alert.You successfully created new category');
      }, err => {
        console.log(err);
        this._alertService.error(err.statusText);
      });
  }
  
}
