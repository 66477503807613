import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appActiveTabs]'
})
export class ActiveTabsDirective {
  
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    let $toggle = false;
    let q: any[] = JSON.parse(localStorage.getItem('openedSubcategories'));
    if (!q) {
      q = [];
      localStorage.setItem('openedSubcategories', JSON.stringify(q));
    } else {
      setTimeout(() => {
        for (let z = 0; z < q.length; z ++) {
          if (q[z] === el.nativeElement.value) {
            $toggle = true;
            renderer.addClass(el.nativeElement, 'open');
          }
        }
      }, );
    }
  }
  
  @HostListener('click') clickEvent() {
    let bla = true;
    let r: any[] = JSON.parse(localStorage.getItem('openedSubcategories'));
    
    if (!r) {
      r = [];
      localStorage.setItem('openedSubcategories', JSON.stringify(r));
    }
    
    if (r.length > 0) {
      let w = JSON.parse(localStorage.getItem('openedSubcategories'));
      for (let y = 0; y < w.length; y++) {
        if (w[y] === this.el.nativeElement.value) {
          let index = w.indexOf(w[y]);
          if (index !== -1) {
            w.splice(index, 1);
          }
          bla = null;
          this.renderer.removeClass(this.el.nativeElement, 'open');
          break;
        }
      }
      if (bla) {
        w.push(this.el.nativeElement.value);
        this.renderer.addClass(this.el.nativeElement, 'open');
      }
      localStorage.setItem('openedSubcategories', JSON.stringify(w));
    } else {
      r.push(this.el.nativeElement.value);
      localStorage.setItem('openedSubcategories', JSON.stringify(r));
      this.renderer.addClass(this.el.nativeElement, 'open');
    }
  }
}
