import {Component, OnInit} from "@angular/core";
import {SettingsService, CategoryService, SharedService} from "../../_services/index";
import {Router} from "@angular/router";

@Component({
	selector: "app-user-attributes",
	templateUrl: "./user-attributes.component.html",
	styleUrls: ["./user-attributes.component.less"]
})
export class UserAttributesComponent implements OnInit {
	
	lang: any = {active: localStorage.getItem("lang")};
	attributesList: any[] = [];
	questionnaires: any[] = [];
	documentTypesList: any = [];
	attributeTypes = [
		{
			id: 0,
			name: "User_attribute_type_0"
		}, {
			id: 1,
			name: "User_attribute_type_1"
		}, {
			id: 2,
			name: "User_attribute_type_2"
		}, {
			id: 3,
			name: "User_attribute_type_3"
		}, {
			id: 4,
			name: "User_attribute_type_4"
		}, {
			id: 5,
			name: "User_attribute_type_5"
		}, {
			id: 6,
			name: "User_attribute_type_6"
		}
	];
	
	constructor(
		private _SettingsService: SettingsService,
		private _CategoriesService: CategoryService,
		private _sharedService: SharedService,
		private router: Router
	) {
		this.getAttributes();
	}
	
	ngOnInit() {
		this._sharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang.active = res;
			this.attributesLangHandler();
			this.categoriesLangHandler();
			this.questionnairesLangHandler();
		});
	}
	
	public addNewUserAttr() {
		this.router.navigate(["/settings/user-attributes/new"]);
	}
	
	public editUserAttr(data) {
		this.router.navigate(["/settings/user-attributes/" + data.id]);
	}
	
	public attributesLangHandler(): void {
		this.attributesList.forEach(i => {
			if (this.lang.active === "en") {
				i["description_to_show"] = i["description"];
			} else {
				for (let key of Object.keys(i["description_translation"])) {
					if (key.toLowerCase() === this.lang.active) {
						if (i["description_translation"][key] !== null) {
							i["description_to_show"] = i["description_translation"][key];
						} else {
							i["description_to_show"] = i["description"];
						}
					}
				}
			}
		});
	}
	
	public categoriesLangHandler(): void {
		this.documentTypesList.forEach(i => {
			if (this.lang.active === "en") {
				i["title_to_show"] = i["title"];
				i["category_title_to_show"] = i["category_title"];
			} else {
				for (let key of Object.keys(i["title_translation"])) {
					if (key.toLowerCase() === this.lang.active) {
						if (i["title_translation"][key] !== null) {
							i["title_to_show"] = i["title_translation"][key];
						} else {
							i["title_to_show"] = i["title"];
						}
					}
				}
				for (let key of Object.keys(i["category_title_translations"])) {
					if (key.toLowerCase() === this.lang.active) {
						if (i["category_title_translations"][key] !== null) {
							i["category_title_to_show"] = i["category_title_translations"][key];
						} else {
							i["category_title_to_show"] = i["category_title"];
						}
					}
				}
			}
		});
		this.attributesList.forEach(i => {
			i.sub_categories.forEach(child_obj => {
				this.documentTypesList.forEach(deepest_obj => {
					if (child_obj.id === deepest_obj.id) {
						child_obj["title_to_show"] = deepest_obj["title_to_show"];
					}
				});
			});
		});
	}
	
	public questionnairesLangHandler(): void {
		this.questionnaires.forEach(i => {
			if (this.lang.active === "en") {
				i["question_to_show"] = i["question"];
				i["category_title_to_show"] = i["category_title"];
			} else {
				for (let key of Object.keys(i.question_translation)) {
					if (key.toLowerCase() === this.lang.active) {
						if (i.question_translation[key]) {
							i["question_to_show"] = i.question_translation[key];
						} else {
							i["question_to_show"] = i["question"];
						}
					}
				}
				
				for (let key of Object.keys(i.category_title_translations)) {
					if (key.toLowerCase() === this.lang.active) {
						if (i["category_title_translations"][key] !== null && i["category_title_translations"][key]) {
							i["category_title_to_show"] = i["category_title_translations"][key];
						} else {
							i["category_title_to_show"] = i["category_title"];
						}
					}
				}
			}
		});
		
		this.attributesList.forEach(i => {
			i.questions.forEach(child_obj => {
				this.questionnaires.forEach(deepest_obj => {
					if (child_obj.id === deepest_obj.id) {
						child_obj["question_to_show"] = deepest_obj["question_to_show"];
					}
				});
			});
		});
	}
	
	public getAttributes(): void {
		this._SettingsService.getAttributes()
		.subscribe(res => {
			this.attributesList = res.result;
			for (let t = 0; t < this.attributesList.length; t++) {
				this.attributesList[t]["attribute_type"] = {
					id: this.attributesList[t].type
				};
				
				switch (this.attributesList[t].type) {
					case 0:
						this.attributesList[t].attribute_type.name = this.attributeTypes[0].name;
						break;
					case 1:
						this.attributesList[t].attribute_type.name = this.attributeTypes[1].name;
						break;
					case 2:
						this.attributesList[t].attribute_type.name = this.attributeTypes[2].name;
						break;
					case 3:
						this.attributesList[t].attribute_type.name = this.attributeTypes[3].name;
						break;
					case 4:
						this.attributesList[t].attribute_type.name = this.attributeTypes[4].name;
						break;
					case 5:
						this.attributesList[t].attribute_type.name = this.attributeTypes[5].name;
						break;
					case 6:
						this.attributesList[t].attribute_type.name = this.attributeTypes[6].name;
						break;
					default:
						this.attributesList[t].attribute_type.name = this.attributeTypes[0].name;
					
				}
				
				for (let tt = 0; tt < this.attributesList[t].sub_categories.length; tt++) {
					if (tt <= 2) {
						this.attributesList[t]["show_all_sub"] = false;
						this.attributesList[t]["sub_categories"][tt]["show"] = true;
					} else {
						this.attributesList[t]["sub_categories"][tt]["show"] = false;
					}
				}
				
				for (let tt = this.attributesList[t].questions.length; tt--;) {
					if (!this.attributesList[t].questions[tt].category_id) {
						let index = this.attributesList[t].questions.indexOf(this.attributesList[t].questions[tt]);
						if (index !== -1) {
							this.attributesList[t].questions.splice(index, 1);
						}
					} else {
						if (tt <= 2) {
							this.attributesList[t]["show_all_questionnaires"] = false;
							this.attributesList[t].questions[tt]["show"] = true;
						} else {
							this.attributesList[t].questions[tt]["show"] = false;
						}
					}
				}
			}
			this.attributesLangHandler();
			this.getQuestionnaire();
			this.getCategories();
		}, err => {
			console.log(err);
		});
	}
	
	public toggleCategories(i): any {
		if (!i.show_all_sub) {
			for (let t = 0; t < i.sub_categories.length; t++) {
				i.sub_categories[t]["show"] = true;
			}
		} else {
			for (let t = 0; t < i.sub_categories.length; t++) {
				(t <= 2) ? i.sub_categories[t]["show"] = true : i.sub_categories[t]["show"] = false;
			}
		}
		i.show_all_sub = !i.show_all_sub;
	}
	
	public toggleQuestionnaires(i): any {
		if (!i.show_all_questionnaires) {
			for (let t = 0; t < i.questions.length; t++) {
				i.questions[t]["show"] = true;
			}
		} else {
			for (let t = 0; t < i.questions.length; t++) {
				(t <= 2) ? i.questions[t]["show"] = true : i.questions[t]["show"] = false;
			}
		}
		i.show_all_questionnaires = !i.show_all_questionnaires;
	}
	
	public getCategories(): void {
		this._CategoriesService.getCategoriesAll()
		.subscribe(res => {
			this.documentTypesList = [];
			res.categories.forEach(obj => {
				if (obj.is_active) {
					obj.subCategories.forEach(child_obj => {
						child_obj["category_title"] = obj.title;
						child_obj["category_title_translations"] = obj.title_translation;
						if (child_obj.is_active) {
							this.documentTypesList.push(child_obj);
						}
					});
					
					this.questionnaires.forEach(i => {
						if (i.category_id === obj.id) {
							let q = Object.assign({}, obj);
							i["category_title"] = q["title"];
							i["category_title_translations"] = q["title_translation"];
						}
					});
				}
			});
			this.categoriesLangHandler();
			this.questionnairesLangHandler();
		}, err => {
			console.log(err);
		});
		
	}
	
	public getQuestionnaire(): void {
		this._CategoriesService.getQuestionnaires()
		.subscribe(res => {
			this.questionnaires = res.result;
			for (let q = this.questionnaires.length; q--;) {
				if (!this.questionnaires[q]["category_id"]) {
					let index = this.questionnaires.indexOf(this.questionnaires[q]);
					if (index !== -1) {
						this.questionnaires.splice(index, 1);
					}
				}
			}
			this.questionnaires.sort((a, b) => {
				return a.category_id - b.category_id;
			});
		}, err => {
			console.log(err);
		});
	}
	
	public deleteAttribute(i): void {
		let q = confirm("Are you sure?");
		if (q) {
			this._SettingsService.deleteAttr(i)
			.subscribe(res => {
				this.getAttributes();
			}, err => {
				console.log(err);
			});
		}
	}
	
}
