import {Directive, ElementRef, Renderer2, HostListener, Output, EventEmitter, OnInit, Input} from '@angular/core';

@Directive({
  selector: '[appTreeZoom]'
})
export class TreeZoomDirective implements OnInit {
  @Output() scaleChange = new EventEmitter();
  // @Input() zoomIn = new EventEmitter();
  
  element;
  currentScale;
  min = '0.6';
  max = '1.3';
  base = '1.0';
  step = '0.1';
  
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.element = this.el.nativeElement;
    this.currentScale = this.element.style.transform.split(/[\(,)]+/)[1];
    if (this.currentScale === undefined) {
      this.currentScale = this.base;
    }
  }
  
  ngOnInit() {
    this.settingStyles(this.currentScale);
  }
  
  settingStyles(currentScale, x?, y?): void {
    if (x === undefined) {
      x = 0;
      y = 0;
    }
    let q = `${x}px ${y}px`;
    
    this.renderer.setStyle(this.element, 'transform', `scale(${currentScale})`);
    this.renderer.setStyle(this.element, 'transform-origin', q);
    this.scaleChange.emit(parseInt((parseFloat(currentScale) * parseFloat('100')).toFixed(1), 10).toString() + '%');
  }
  
  zoomIn(): void {
    if (this.currentScale <= this.max) {
      this.currentScale = (parseFloat(this.currentScale) + parseFloat(this.step)).toFixed(1);
    }
    this.settingStyles(this.currentScale);
  }
  zoomOut(): void {
    if (this.currentScale >= this.min) {
      this.currentScale = (parseFloat(this.currentScale) - parseFloat(this.step)).toFixed(1);
    }
    this.settingStyles(this.currentScale);
  }
  resetZoom(): void {
    this.currentScale = this.base;
    this.settingStyles(this.currentScale);
  }
  
  @HostListener('dblclick', ['$event']) onMouseClick($event) {
    console.log($event);
    console.log(this.el);
  }
  
  @HostListener('mousewheel', ['$event']) onMousewheel($event) {
    if ($event.ctrlKey) {
      event.preventDefault();
      if ($event.wheelDelta > 0) {
        if (this.currentScale <= this.max) {
          this.currentScale = (parseFloat(this.currentScale) + parseFloat(this.step)).toFixed(1);
        }
      } else {
        if (this.currentScale >= this.min) {
          this.currentScale = (parseFloat(this.currentScale) - parseFloat(this.step)).toFixed(1);
        }
      }
  
      let bounds = this.el.nativeElement.getBoundingClientRect();
      let x = $event.pageX;
      let y = $event.pageY;
      let cX = $event.offsetX;
      let cY = $event.offsetY;
      console.log($event);
      console.log(this.el);
      console.log(bounds);
      console.log('MOUSE: ' + $event.pageX + ' / ' + $event.pageY);
      console.log('Client: ' + cX + ' / ' + cY);
      
      
      this.settingStyles(this.currentScale, Math.abs(bounds.left), bounds.top);
    }
  }

}
