<div class="container">
  <div class="row">
    <div class="categories sidebar col-4 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <div class="categories-header">
        <span>{{'DASHBOARD.Categories' | translate}}</span>
        <button class="main-action-btn no-text-btn" [routerLink]="['/category/new']">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="categories-list-wrapper">
        <ul>
          <li class="subcategories-wrapper" [hidden]="!i.is_active" [ngClass]="{'data_null': !i.title}" *ngFor="let i of categories" value="{{i.id}}" data-title="{{i.category_title_to_show}}" categoryID="{{i.id}}" appActiveCategoryTabs>

            <img (click)="scrollTo()" [src]="i.image_path || imagePlaceholder" [alt]="i.image_path || imagePlaceholder">

            <div (click)="scrollTo()" class="subcategory-content-wrapper">
              <span class="title-span" *ngIf="lang.active === 'en'">{{i.title || 'Shared.No_title' | translate}}</span>
              <span class="title-span" *ngIf="lang.active === 'ru'">{{i.title_translation.RU || i.title}}</span>

              <span [class.open]="i.show_descr" (click)="$event.stopPropagation(); i.show_descr = !i.show_descr" class="category-description" [ngClass]="{'data_null': !i.description && !i.description_translation.RU}">
                <span class="descr-span" *ngIf="lang.active === 'en'">
                  {{i.description || 'Shared.No_description' | translate}}
                </span>
                <span class="descr-span" *ngIf="lang.active === 'ru'">
                  {{i.description_translation.RU || i.description || 'Shared.No_description' | translate}}
                </span>
              </span>
            </div>

            <div class="dropdown context_menu_wrapper">
              <button class="context_menu_btn" type="button" id="category_context_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i  class="fas fa-ellipsis-h"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="category_context_menu">
                <a [routerLink]="['/category/'+ i.id +'/edit']">{{'Shared.Edit' | translate}}</a>
                <a (click)="deleteCategory(i)">{{'Shared.Delete' | translate}}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="subcategories main-content col-8 col-sm-12 col-md-8 col-lg-8 col-xl-8">
      <div class="subcategories-header">

        <div class="tabs">
          <div (click)="tabHandler(1)" [class.active]="tab === 1" class="tab subcategories-header-title">
            <span>{{'DASHBOARD.Subcategories' | translate}}</span>
            <i class="fas fa-plus-circle" *ngIf="subcategories" (click)="createSubcategoryLink()"></i>
          </div>
          <div (click)="tabHandler(2)" [class.active]="tab === 2" class="tab questionnaire-header-title">
            <span>{{'DASHBOARD.Questionnaire' | translate}}</span>
            <i class="fas fa-plus-circle" *ngIf="subcategories" (click)="createQuestionnaireLink()"></i>
          </div>
        </div>

      </div>
      <div class="subcategories-list-wrapper" *ngIf="tab === 1">
        <ul>
          <li class="subcategories-translations-wrapper" [hidden]="!q.is_active" *ngFor="let q of subcategories" value="{{q.id}}" appActiveTabs>

            <div class="subcategory_title_wrapper">

              <span class="subcategory_title">
                <span *ngIf="lang.active === 'en'">{{q.title || 'Shared.No_title' | translate}}</span>
                <span *ngIf="lang.active === 'ru'">{{q.title_translation.RU || q.title}}</span>
              </span>

              <span class="controls">
                <i [routerLink]="['/category/' + q.category_id + '/subcategory/' + q.id + '/edit']" class="fas fa-pencil-alt"></i>
                <i class="far fa-trash-alt" (click)="$event.stopPropagation(); deleteSubcategory(q)"></i>
              </span>
            </div>
            <div class="subcategory_descr_wrapper">
              <span *ngIf="lang.active === 'en'">
                {{q.description}}
              </span>
              <span *ngIf="lang.active === 'ru'">
                {{q.description_translation.RU || q.description}}
              </span>
            </div>

            <div (click)="$event.stopPropagation()" class="subcategories-translations">

              <div class="subcategories-data-wrapper">
                <div class="subcategory-title EN">
                  <span class="language_identifier">{{'Shared.Title' | translate}} (EN)</span>
                  <span class="subcategory_title_data"  [ngClass]="{'data_null': !q.title}">
                    {{q.title || 'Shared.No_subcategory_title' | translate}}
                  </span>
                </div>
                <div class="subcategory-descr EN">
                  <span class="language_identifier">{{ 'Shared.Description' | translate }} (EN)</span>
                  <span class="subcategory_descr_data" [ngClass]="{'data_null': !q.description}">
                    {{q.description || 'Shared.No_subcategory_description' | translate}}
                  </span>
                </div>
              </div>

              <div class="subcategories-data-wrapper" *ngFor="let t of q.translations">
                <div *ngFor="let key of objectKeys(t)">
                  <div class="subcategory-title {{key}}">
                    <span class="language_identifier">{{'Shared.Title' | translate}} ({{key}})</span>
                    <span class="subcategory_title_data" [ngClass]="{'data_null': !t[key].title}">
                      {{t[key].title || 'Shared.No_subcategory_title' | translate}}
                    </span>
                  </div>
                  <div class="subcategory-descr {{key}}">
                    <span class="language_identifier">{{ 'Shared.Description' | translate }} ({{key}})</span>
                    <span class="subcategory_descr_data" [ngClass]="{'data_null': !t[key].descr}">
                      {{t[key].descr || 'Shared.No_subcategory_description' | translate}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="questionnaire-list-wrapper" *ngIf="tab === 2">
        <ul>
          <li class="subcategories-translations-wrapper" *ngFor="let j of questionnaireArray" value="{{j.id}}" appActiveTabsQ>

            <div class="subcategory_title_wrapper">
              <span class="subcategory_title">
                <span *ngIf="lang.active === 'en'">{{j.question || 'Shared.No_title' | translate}}</span>
                <span *ngIf="lang.active === 'ru'">{{j.question_translation.RU || j.question}}</span>
              </span>
              <span class="controls">
                <i [routerLink]="['/category/' + j.category_id + '/questionnaire/' + j.id + '/edit']" class="fas fa-pencil-alt"></i>
                <i class="far fa-trash-alt" (click)="$event.stopPropagation(); deleteQuestionnaire(j)"></i>
              </span>
            </div>
            <div (click)="$event.stopPropagation()" class="subcategories-translations">

              <div class="subcategories-data-wrapper">
                <div class="subcategory-title EN">
                  <span class="language_identifier">{{'Shared.Question' | translate}} (EN)</span>
                  <span class="subcategory_title_data"  [ngClass]="{'data_null': !j.question}">
                    {{j.question || 'Shared.No_subcategory_title' | translate}}
                  </span>
                </div>
              </div>

              <div class="subcategories-data-wrapper" *ngFor="let key of objectKeys(j.question_translation)">
                <div class="subcategory-title {{key}}">
                  <span class="language_identifier">{{'Shared.Question' | translate}} ({{key}})</span>
                  <span class="subcategory_title_data" [ngClass]="{'data_null': !j.question_translation[key]}">
                      {{j.question_translation[key] || 'Shared.No_subcategory_title' | translate}}
                    </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
