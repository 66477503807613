import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {EnvService} from "./env.service";
import * as AWS from "aws-sdk";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
	providedIn: "root"
})
export class AwsHandlerService {
	
	s3: any;
	env: any;
	
	constructor(
		private _env: EnvService,
		private _sanitizer: DomSanitizer
	) {
		this.env = _env;
	}
	
	public handleAWSimages(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		
		data["user_periods"].forEach(obj => {
			obj["subcats"].forEach(child_obj => {
				child_obj["docs"].forEach(deep_obj => {
					deep_obj["document_items_blobs"] = [];
					if (deep_obj["document_items"] === undefined) {
						deep_obj["document_items"] = [];
					}
				});
			});
		});
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["preview"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(user_periods, s3, s3_params, _sanitizer) {
			for (let period of user_periods) {
				for (let subcats of period["subcats"]) {
					for (let document of subcats["docs"]) {
						document["document_items_blobs"] = [];
						for (let item of document["document_items"]) {
							if (item.ext === "pdf") {
								document["document_items_blobs"].push({
									preview: item.preview,
									full: "",
									ext: item.ext,
									id: item.id,
									created_at: item.created_at
								});
							} else {
								let signedUrl = await getActualImage(item, s3, s3_params);
								let url = window.URL;
								let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
								let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
								document["document_items_blobs"].push({
									preview: trusted_blob,
									full: "",
									ext: item.ext,
									id: item.id,
									created_at: item.created_at
								});
							}
						}
					}
				}
			}
			return user_periods;
		}
		
		return new Observable(observer => {
			process(data["user_periods"], this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
	
	public handleAWSimagesFull(data): any {
		
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		
		function getActualFullImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["full"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(arr, s3, s3_params, _sanitizer) {
			for (let [i, t] of arr["document_items"].entries()) {
				let aws_image = await getActualFullImage(t, s3, s3_params);
				let url = window.URL;
				let blob;
				if (t.ext === "pdf") {
					blob = new Blob([aws_image["Body"]], {type: "application/pdf"});
				} else {
					blob = new Blob([aws_image["Body"]], {type: "image/jpeg"});
				}
				// let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
				arr["document_items_blobs"][i]["full"] = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
			}
			
			return arr;
		}
		
		return new Observable(observer => {
			process(data, this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
		
	}
	
	public handleSingleAWSimageFull(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key.split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		console.log(data);
		
		async function process(s3, s3_params, _sanitizer) {
			let signedUrl = await getActualImage(data, s3, s3_params);
			let url = window.URL;
			let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
			console.log(blob);
			return _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
		}
		
		
		return new Observable(observer => {
			process(this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
	
	public handleAWSimagesAfterUpload(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["preview"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(s3, s3_params, _sanitizer) {
			for (let item of data["document_items"]) {
				console.log(item);
				if (item.ext === "pdf") {
					data["document_items_blobs"].push({
						preview: item.preview,
						full: "",
						ext: item.ext,
						id: item.id,
						created_at: item.created_at
					});
				} else {
					let signedUrl = await getActualImage(item, s3, s3_params);
					let url = window.URL;
					let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
					let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
					data["document_items_blobs"].push({
						preview: trusted_blob,
						full: "",
						ext: item.ext,
						id: item.id,
						created_at: item.created_at
					});
				}
			}
			return data;
		}
		
		return new Observable(observer => {
			process(this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
	
	// Handling tax calculation and declarations files separately from others because of the absence of the 'subcategory' in the equation
	public handleAWSTaxCalcAndDeclarationFiles(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		
		data["user_periods"].forEach(obj => {
			obj["docs"].forEach(deep_obj => {
				deep_obj["document_items_blobs"] = [];
				if (deep_obj["document_items"] === undefined) {
					deep_obj["document_items"] = [];
				}
			});
		});
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["preview"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(user_periods, s3, s3_params, _sanitizer) {
			for (let period of user_periods) {
				for (let document of period["docs"]) {
					document["document_items_blobs"] = [];
					for (let item of document["document_items"]) {
						if (item.ext === "pdf") {
							document["document_items_blobs"].push({
								preview: item.preview,
								full: "",
								ext: item.ext,
								id: item.id,
								created_at: item.created_at
							});
						} else {
							let signedUrl = await getActualImage(item, s3, s3_params);
							let url = window.URL;
							let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
							let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
							document["document_items_blobs"].push({
								preview: trusted_blob,
								full: "",
								ext: item.ext,
								id: item.id,
								created_at: item.created_at
							});
						}
					}
				}
			}
			return user_periods;
		}
		
		return new Observable(observer => {
			process(data["user_periods"], this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
	
	public handleAWSOfficialConfirmationFiles(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		
		data["user_periods"].forEach(obj => {
			obj["docs"].forEach(deep_obj => {
				deep_obj["document_items_blobs"] = [];
				if (deep_obj["document_items"] === undefined) {
					deep_obj["document_items"] = [];
				}
			});
		});
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["preview"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(user_periods, s3, s3_params, _sanitizer) {
			for (let period of user_periods) {
				for (let document of period["docs"]) {
					document["document_items_blobs"] = [];
					for (let item of document["document_items"]) {
						if (item.ext === "pdf") {
							document["document_items_blobs"].push({
								preview: item.preview,
								full: "",
								ext: item.ext,
								id: item.id,
								created_at: item.created_at
							});
						} else {
							let signedUrl = await getActualImage(item, s3, s3_params);
							let url = window.URL;
							let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
							let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
							document["document_items_blobs"].push({
								preview: trusted_blob,
								full: "",
								ext: item.ext,
								id: item.id,
								created_at: item.created_at
							});
						}
					}
				}
			}
			return user_periods;
		}
		
		return new Observable(observer => {
			process(data["user_periods"], this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
	
	public handleAWSPowerOfAttorneyFiles(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		console.log(data);
		data["poa_documents"].forEach(obj => {
			obj["document_items_blobs"] = [];
			if (obj["document_items"] === undefined) {
				obj["document_items"] = [];
			}
		});
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["preview"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(user_documents, s3, s3_params, _sanitizer) {
			for (let document of user_documents) {
				document["document_items_blobs"] = [];
				for (let item of document["document_items"]) {
					if (item.ext === "pdf") {
						document["document_items_blobs"].push({
							preview: item.preview,
							full: "",
							ext: item.ext,
							id: item.id,
							created_at: item.created_at
						});
					} else {
						let signedUrl = await getActualImage(item, s3, s3_params);
						let url = window.URL;
						let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
						let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
						document["document_items_blobs"].push({
							preview: trusted_blob,
							full: "",
							ext: item.ext,
							id: item.id,
							created_at: item.created_at
						});
					}
				}
			}
			return user_documents;
		}
		
		return new Observable(observer => {
			process(data["poa_documents"], this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
	
	public handleAWSTaxAdvisorAccountsFiles(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		console.log(data);
		data["user_tax_adv_documents"].forEach(obj => {
			obj["document_items_blobs"] = [];
			if (obj["document_items"] === undefined) {
				obj["document_items"] = [];
			}
		});
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["preview"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(user_documents, s3, s3_params, _sanitizer) {
			for (let document of user_documents) {
				document["document_items_blobs"] = [];
				for (let item of document["document_items"]) {
					if (item.ext === "pdf") {
						document["document_items_blobs"].push({
							preview: item.preview,
							full: "",
							ext: item.ext,
							id: item.id,
							created_at: item.created_at
						});
					} else {
						let signedUrl = await getActualImage(item, s3, s3_params);
						let url = window.URL;
						let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
						let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
						document["document_items_blobs"].push({
							preview: trusted_blob,
							full: "",
							ext: item.ext,
							id: item.id,
							created_at: item.created_at
						});
					}
				}
			}
			return user_documents;
		}
		
		return new Observable(observer => {
			process(data["user_tax_adv_documents"], this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
	
	public handleTaxidAWSimages(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		
		data["attributes_taxid"].forEach(obj => {
			obj["subcats"].forEach(child_obj => {
				child_obj["docs"].forEach(deep_obj => {
					deep_obj["document_items_blobs"] = [];
					if (deep_obj["document_items"] === undefined) {
						deep_obj["document_items"] = [];
					}
				});
			});
		});
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["preview"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(user_periods, s3, s3_params, _sanitizer) {
			for (let period of user_periods) {
				for (let subcats of period["subcats"]) {
					for (let document of subcats["docs"]) {
						document["document_items_blobs"] = [];
						for (let item of document["document_items"]) {
							if (item.ext === "pdf") {
								document["document_items_blobs"].push({
									preview: item.preview,
									full: "",
									ext: item.ext,
									id: item.id,
									created_at: item.created_at
								});
							} else {
								let signedUrl = await getActualImage(item, s3, s3_params);
								let url = window.URL;
								let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
								let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
								document["document_items_blobs"].push({
									preview: trusted_blob,
									full: "",
									ext: item.ext,
									id: item.id,
									created_at: item.created_at
								});
							}
						}
					}
				}
			}
			return user_periods;
		}
		
		return new Observable(observer => {
			process(data["attributes_taxid"], this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
	
	public handleBuildTaxidAWSimages(data): any {
		this.s3 = new AWS.S3({
			region: this.env.region,
			signatureVersion: this.env.signatureVersion,
			accessKeyId: this.env.accessKeyId,
			secretAccessKey: this.env.secretAccessKey
		});
		let params = {
			Bucket: this.env.Bucket
		};
		
		data["attributes_build_taxid"].forEach(obj => {
			obj["subcats"].forEach(child_obj => {
				child_obj["docs"].forEach(deep_obj => {
					deep_obj["document_items_blobs"] = [];
					if (deep_obj["document_items"] === undefined) {
						deep_obj["document_items"] = [];
					}
				});
			});
		});
		
		function getActualImage(key, s3, s3_params) {
			return new Promise((resolve, reject) => {
				s3_params["Key"] = key["preview"].split("amazonaws.com/").pop();
				s3.getObject(s3_params, function (err, res) {
					resolve(res);
				});
			});
		}
		
		async function process(user_periods, s3, s3_params, _sanitizer) {
			for (let period of user_periods) {
				for (let subcats of period["subcats"]) {
					for (let document of subcats["docs"]) {
						document["document_items_blobs"] = [];
						for (let item of document["document_items"]) {
							if (item.ext === "pdf") {
								document["document_items_blobs"].push({
									preview: item.preview,
									full: "",
									ext: item.ext,
									id: item.id,
									created_at: item.created_at
								});
							} else {
								let signedUrl = await getActualImage(item, s3, s3_params);
								let url = window.URL;
								let blob = new Blob([signedUrl["Body"]], {type: "image/jpeg"});
								let trusted_blob = _sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
								document["document_items_blobs"].push({
									preview: trusted_blob,
									full: "",
									ext: item.ext,
									id: item.id,
									created_at: item.created_at
								});
							}
						}
					}
				}
			}
			return user_periods;
		}
		
		return new Observable(observer => {
			process(data["attributes_build_taxid"], this.s3, params, this._sanitizer)
			.then(res => {
				observer.next(res);
			});
		});
	}
}
