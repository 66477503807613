import { Component, OnInit } from '@angular/core';
import { CategoryService, AlertService, SettingsService } from "../../../_services/index";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-create-questionnaire',
  templateUrl: './create-questionnaire.component.html',
  styleUrls: ['./create-questionnaire.component.less']
})
export class CreateQuestionnaireComponent implements OnInit {
  
  category_id = this.route.snapshot.params['id'];
  objectKeys = Object.keys;
  questionnaireModel: any = {
    title: '',
    description: ''
  };
  translations: any[] = [];
  t: any[] = [];
  d: any[] = [];
  
  constructor(
    private _categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private _alertService: AlertService,
    private _settingsService: SettingsService
  ) {
    this.getRegisteredLanguages();
  }
  
  ngOnInit() {}
  
  getRegisteredLanguages(): void {
    this._settingsService.getRegisteredLanguages()
      .subscribe(res => {
        for (let i = 0; i < res.languages.length; i++) {
          if (res.languages[i]['lang_code'] !== 'EN') {
            let tempObj = Object.assign({}, {
              [res.languages[i]['lang_code']]: {
                'title': '',
                'descr': ''
              }
            });
            this.translations.push(tempObj);
          }
        }
      }, err => {
        console.log(err);
      });
  }
  
  createQuestionnaire(EN_data, translations): void {
    this.t = [];
    for (let i = 0; i < translations.length; i++) {
      for (const key of Object.keys(translations[i])) {
        this.t.push({
          'language_code': key,
          'question': translations[i][key].title
        });
      }
    }
    this.t.push({
      'language_code': 'EN',
      'question': EN_data.title
    });
    
    let objToSend = {
      questions: this.t,
      category_id: parseInt(this.category_id, 10)
    };
    console.log(objToSend);
    this._categoryService.createNewQuestionnaire(objToSend)
      .subscribe(res => {
        this._alertService.success('Alert.You_successfully_created_new_subcategory');
        this.router.navigate(['/categories']);
      }, err => {
        console.log(err);
        this._alertService.error(err.statusText);
      });
  }

}
