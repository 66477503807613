import { Component, OnInit } from '@angular/core';
import { CategoryService, AlertService } from "../../_services/index";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-edit-subcategory',
  templateUrl: './edit-subcategory.component.html',
  styleUrls: ['./edit-subcategory.component.less']
})
export class EditSubcategoryComponent implements OnInit {
  
  objectKeys = Object.keys;
  subcategory_id: any = this.route.snapshot.params['sub_id'];
  subcategoryData: any = {};
  translations: any[] = [];
  t: any[] = [];
  d: any[] = [];
  
  constructor(
    private _categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private _alertService: AlertService
  ) {}

  ngOnInit() {
    this.getSingleSubcategoryById();
  }
  
  onDataChange(type, identifier, data): void {
    
    let pushFunc = (array) => {
      array.push({
        'language_code': identifier,
        'translation': data
      });
    };
    
    if (type === 'title') {
      this.t.forEach((item, index) => {
        if (item.language_code === identifier) {
          this.t.splice(index, 1);
        }
      });
      pushFunc(this.t);
    } else {
      this.d.forEach((item, index) => {
        if (item.language_code === identifier) {
          this.d.splice(index, 1);
        }
      });
      pushFunc(this.d);
    }
  }
  
  getSingleSubcategoryById(): void {
    this._categoryService.getSingleSubcategory(this.subcategory_id)
      .subscribe(res => {
        console.log(res);
        for (const w of Object.keys(res.categories.title_translation)) {
          let i = {
            [w]: {
              'title': res.categories.title_translation[w]
            }
          };
          for (const q of Object.keys(res.categories.description_translation)) {
            if (q === w) {
              i[w]['descr'] = res.categories.description_translation[q];
            }
          }
          this.translations.push(i);
        }
        this.subcategoryData = res.categories;
      }, err => {
        console.log(err);
      });
  }
  
  editSubcategory(data): void {
    let objToSend = {
      subcategory_id: data.id,
      title_EN: data.title,
      category_id: data.category_id,
      title_translations: this.t,
      description_translations: this.d
    };
    
    this._categoryService.editSingleSubcategory(objToSend)
      .subscribe(res => {
        console.log(res);
        this._alertService.success('Alert.Changes saved successfully');
        this.router.navigate(['/categories']);
      }, err => {
        console.log(err);
      });
  }

}
