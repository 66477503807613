// INTERNAL MODULES
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";

// DIRECTIVES
import { PermissionDirective, ActiveTabsDirective, ActiveTabsDirectiveQ, ConfirmEqualValidatorDirective, ActiveCategoryTabsDirective, AwsTunnelDirective, LazyLoadDirective, TreeZoomDirective, DateValidatorDirective, EmailValidatorDirective, NumbersValidatorDirective, TopbarScrollDirective, FileDropDirective, TooltipDirective } from "../../_directives/index";

// COMPONENTS
import { AlertComponent } from "../components/alert/alert.component";

// PIPES
import { FilterPipe, OrderByPipe } from '../../_pipes/index';

// EXTERNAL MODULES
import { NgxPaginationModule } from "ngx-pagination";
import { TranslateModule } from '@ngx-translate/core';
import { TabsModule, BsDropdownModule, ModalModule, BsDatepickerModule, CarouselModule, TooltipModule } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { NgSelectModule } from '@ng-select/ng-select';
import { DragScrollModule } from "ngx-drag-scroll/lib";
import { InternationalPhoneNumberModule } from "ngx-international-phone-number-improved-final";


@NgModule({
  declarations: [
    FilterPipe,
    OrderByPipe,
    
    PermissionDirective,
    LazyLoadDirective,
    AwsTunnelDirective,
    ActiveTabsDirective,
    ActiveTabsDirectiveQ,
    ConfirmEqualValidatorDirective,
    ActiveCategoryTabsDirective,
    TreeZoomDirective,
    DateValidatorDirective,
    EmailValidatorDirective,
    NumbersValidatorDirective,
    TopbarScrollDirective,
    FileDropDirective,
    TooltipDirective,
    
    AlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TranslateModule,
    NgSelectModule,
    DragScrollModule,
    DragDropModule,
    InternationalPhoneNumberModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot()
  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TranslateModule,
    NgSelectModule,
    DragScrollModule,
    DragDropModule,
    ModalModule,
    CarouselModule,
    BsDatepickerModule,
    BsDropdownModule,
    TooltipModule,
    InternationalPhoneNumberModule,
    
    FilterPipe,
    OrderByPipe,
  
    PermissionDirective,
    LazyLoadDirective,
    AwsTunnelDirective,
    ActiveTabsDirective,
    ActiveTabsDirectiveQ,
    ConfirmEqualValidatorDirective,
    ActiveCategoryTabsDirective,
    TreeZoomDirective,
    DateValidatorDirective,
    EmailValidatorDirective,
    NumbersValidatorDirective,
    TopbarScrollDirective,
    FileDropDirective,
    TooltipDirective,
    
    AlertComponent
  ]
})
export class SharedLazyModule {
  /*static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedLazyModule,
      providers: [/!*BsModalService*!/]
    };
  }*/
}
