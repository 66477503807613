import { Component, OnInit, TemplateRef } from '@angular/core';
import { SettingsService, AlertService } from "../../_services/index";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { TranslateService } from "@ngx-translate/core";

import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.less']
})
export class LanguageComponent implements OnInit {
  
  modalRef: BsModalRef;
  
  translationsImportForm: FormGroup;
  translationsExportForm: FormGroup;
  newLangForm: FormGroup;
  registeredLanguages: any[] = [];
  isoLangNames: any[] = [];
  exportLangs: any[] = [];
  exportLanguageTo: any;
  
  constructor(
    private _SettingsService: SettingsService,
    private _alertService: AlertService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private translate: TranslateService
  ) {
    this.getRegisteredLanguages();
    this.importForm();
    this.exportForm();
    this.langForm();
  }

  ngOnInit() { }
  
  openImportModal(template: TemplateRef<any>) {
    this.translationsImportForm.reset();
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ 'class': 'importModal' })
    );
  }
  
  addNewLanguageModal(template: TemplateRef<any>) {
    this._SettingsService.getIsoLangNames(this.registeredLanguages)
      .subscribe(data => {
        this.isoLangNames = data;
      });
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ 'class': 'newLanguageModal' })
    );
  }
  
  openExportModal(template: TemplateRef<any>, languageTranslateTo) {
    this.exportLanguageTo = languageTranslateTo;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ 'class': 'exportModal' })
    );
    this.exportLangs = [];
    for (let y = 0; y < this.registeredLanguages.length; y++) {
      if (this.registeredLanguages[y].lang_code === "EN" || this.registeredLanguages[y].lang_code === "RU" || this.registeredLanguages[y].lang_code === "DE") {
        this.exportLangs.push(this.registeredLanguages[y]);
      }
    }
    for (let i = 0; i < this.exportLangs.length; i++) {
      if (this.exportLangs[i].lang_code === languageTranslateTo) {
        let index = this.exportLangs.indexOf(this.exportLangs[i]);
        this.exportLangs.splice(index, 1);
      }
    }
  }
  
  public importForm(): void {
    this.translationsImportForm = this.fb.group({
      file: [null, [Validators.required]]
    });
  }
  public exportForm(): void {
    this.translationsExportForm = this.fb.group({
      select: [null, [Validators.required]]
    });
  }
  public langForm(): void {
    this.newLangForm = this.fb.group({
      langSelect: [null, [Validators.required]]
    });
  }
  
  public getRegisteredLanguages(): void {
    this._SettingsService.getRegisteredLanguages()
      .subscribe(res => {
        this._SettingsService.getSpecificIsoLangNames(res.languages)
          .subscribe(data => {
            this.registeredLanguages = data;
          });
      }, err => {
        console.log(err);
      });
  }
  
  public exportTranslations(): any {
    this._SettingsService.exportTranslations({
        language_code_from: this.translationsExportForm.value.select,
        language_code_to: this.exportLanguageTo
      })
      .subscribe(res => {
        console.log(res);
        const blob = new Blob([res], {
          type: 'application/zip'
        });
        FileSaver.saveAs(blob, `From ${this.translationsExportForm.value.select} to ${this.exportLanguageTo}`);
        this.modalRef.hide();
      }, err => {
        console.log(err);
        this._alertService.error('Alert.Opps_something_went_wrong');
      });
  }
  
  public importTranslations(e): void {
    
    let formData;
    let childNode = e.target.children[0].files;
    if (childNode && childNode.length) {
      formData = childNode[0];
    }
    this._SettingsService.importTranslations(formData)
      .subscribe(res => {
        console.log(res);
        this.modalRef.hide();
        this.getRegisteredLanguages();
        this.translationsImportForm.reset();
  
        this._alertService.success('Alert.Translations_have_been_uploaded_successfully');
      }, err => {
        console.log(err);
        this._alertService.error('Alert.Opps_something_went_wrong');
      });
  }
  
  public addNewLanguage(i): void {
    this._SettingsService.addNewLanguage(this.newLangForm.value.langSelect)
      .subscribe(res => {
        console.log(res);
        this.modalRef.hide();
        this.getRegisteredLanguages();
        this._alertService.success('Alert.New_language_has_been_added');
      }, err => {
        console.log(err);
        this._alertService.error('Alert.Opps_something_went_wrong');
      });
  }
  
  public deleteLanguage(i): void {
    let translated_msg;
    this.translate.get('Alert.Are_you_sure_that_you_want_to_delete_this_language')
      .subscribe((res: Object) => {
        translated_msg = res;
      });
    let q = confirm(translated_msg);
    if (q) {
      this._SettingsService.deleteLanguage(i.lang_code)
        .subscribe(res => {
          console.log(res);
          this.getRegisteredLanguages();
          this._alertService.success('Alert.Language_has_been_deleted');
        }, err => {
          console.log(err);
          this._alertService.error('Alert.Opps_something_went_wrong');
        });
    }
  }

}
