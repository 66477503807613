import {Component, OnInit, TemplateRef} from "@angular/core";
import {SettingsService, AlertService, SharedService} from "../../_services/index";
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-faq",
	templateUrl: "./faq.component.html",
	styleUrls: ["./faq.component.less"]
})
export class FaqComponent implements OnInit {
	
	modalRef: BsModalRef;
	faq: any[] = [];
	registeredLangs: any[] = [];
	
	newQuestion: any = {
		question: "",
		answer: ""
	};
	
	translationsImportForm: FormGroup;
	activeLang: string = localStorage.getItem("lang").toUpperCase();
	
	constructor(
		private _SettingsService: SettingsService,
		private _SharedService: SharedService,
		private fb: FormBuilder,
		private modalService: BsModalService,
		private _AlertService: AlertService,
		private translate: TranslateService
	) {
	}
	
	ngOnInit() {
		this.getFAQ();
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.activeLang = res.toUpperCase();
		});
	}
	
	openNewFaqModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			template,
			Object.assign({"class": "newFaqModal"})
		);
	}
	
	public getFAQ(): void {
		this._SettingsService.getFaqQuestions()
		.subscribe(res => {
			console.log(res);
			this.faq = res.result;
			this.getRegisteredLanguages(this.faq);
		}, err => {
			console.log(err);
		});
	}
	
	public getRegisteredLanguages(faq): void {
		this._SettingsService.getRegisteredLanguages()
		.subscribe(res => {
			this._SettingsService.getSpecificIsoLangNames(res.languages)
			.subscribe(data => {
				data.forEach(obj => {
					obj["faqs"] = [];
					obj["language_code"] = obj["lang_code"];
					obj["new_faq"] = {
						question: "",
						answer: ""
					};
				});
				data.forEach(obj => {
					obj["language_code"] = obj["lang_code"];
					faq.forEach(c_obj => {
						let question = "question_" + obj["language_code"].toUpperCase();
						let answer = "answer_" + obj["language_code"].toUpperCase();
						if (c_obj[question] === undefined) {
							c_obj[question] = "";
						}
						if (c_obj[answer] === undefined) {
							c_obj[answer] = "";
						}
						obj["faqs"].push({
							faq_id: c_obj.id,
							question: c_obj[question],
							answer: c_obj[answer]
						});
					});
				});
				this.registeredLangs = data;
			});
		}, err => {
			console.log(err);
		});
	}
	
	public editFaq(data, lang_code): void {
		let objToSent = {
			faqs: [{
				language_code: lang_code.lang_code,
				question: data.question,
				answer: data.answer
			}],
			faq_id: data.faq_id
		};
		
		this._SettingsService.editFaq(objToSent)
		.subscribe(res => {
			console.log(res);
			data["edit"] = !data["edit"];
		}, err => {
			console.log(err);
		});
	}
	
	public deleteFaq(data): void {
		let translated_msg;
		this.translate.get("Alert.Are_you_sure_you_want_to_delete_faq")
		.subscribe(res => {
			translated_msg = res;
			let t = confirm(translated_msg);
			if (t) {
				this._SettingsService.deleteFaq({
					ids: data.faq_id
				}).subscribe(res1 => {
					this.getFAQ();
					this._AlertService.success("Alert.FAQ_deleted_success");
				}, err => {
					this._AlertService.error("Alert.Opps_something_went_wrong");
				});
			}
		});
	}
	
	public addNewFaq(data): void {
		let objToSent = {faqs: []};
		data.forEach(res => {
			objToSent["faqs"].push({
				language_code: res.language_code,
				question: res.new_faq["question"],
				answer: res.new_faq["answer"]
			});
		});
		
		this._SettingsService.addNewFaq(objToSent)
		.subscribe(res => {
			this.modalRef.hide();
			this.getFAQ();
			this._AlertService.success("Alert.New_FAQ_was_added");
		}, err => {
			console.log(err);
			this._AlertService.error("Alert.Opps_something_went_wrong");
		});
		
	}
	
}
