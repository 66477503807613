<div class="outlet-wrapper">
	<div class="outlet-header">
		<span class="faq_title">{{ 'Settings.Email_setup.Page_title' | translate}}</span>
		<button *ngIf="showSaveBtn" class="main-action-btn no-icon-btn" form="emailSetupForm">
			<span>{{ 'Settings.Email_setup.Save_changes_btn' | translate}}</span>
		</button>
	</div>
	<div class="outlet-main-content">
		<form name="emailSetupForm" id="emailSetupForm" class="emailSetupForm col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" [formGroup]="emailSetupForm" (ngSubmit)="emailSetupForm.valid && submitEmailChange(emailSetupForm)" #f="ngForm" [ngClass]="{ 'ng-submitted-error': f.submitted && f.form.invalid }" novalidate>
			<div class="form-group">
				<div class="input-wrapper email">
					<label for="email">Email</label>
					<input type="email" class="form-control" name="email" id="email" formControlName="email" (keydown)="showSaveBtn = true"/>
				</div>
				<div class="form-control-feedback"
					 *ngIf="f.submitted && emailSetupForm.controls.email.errors">
					<p *ngIf="emailSetupForm.controls.email.errors.required">{{'Shared.Field_is_required' | translate}}</p>
					<p *ngIf="emailSetupForm.controls.email.errors.pattern">{{'Shared.Email_isnt_valid' | translate}}</p>
					<p *ngIf="emailSetupForm.controls.email.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
				</div>
			</div>
		</form>
	</div>
</div>
