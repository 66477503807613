import {Component, OnInit, Renderer2} from "@angular/core";
import {AlertService} from "../../../_services/index";
import {Alert, AlertType} from "../../../_models/index";

@Component({
	moduleId: module.id,
	selector: "app-alert",
	templateUrl: "./alert.component.html",
	styleUrls: ["./alert.component.less"]
})
export class AlertComponent implements OnInit {
	
	alerts: Alert[] = [];
	
	constructor(
		private alertService: AlertService,
		private renderer: Renderer2
	) {
	}
	
	ngOnInit() {
		this.alertService.getAlert().subscribe((alert: Alert) => {
			if (!alert) {
				// clear alerts when an empty alert is received
				this.alerts = [];
				return;
			}
			
			if (!this.alerts.filter((q) => {
				return q.message === alert.message
			}).length) {
				this.alerts.push(alert);
			}
			
			setTimeout(() => {
				this.removeAlert(alert, null);
			}, 5000);
		});
	}
	
	removeAlert(alert: Alert, e?: any, child?: boolean) {
		if (e && !child) {
			this.renderer.addClass(e.target, "slide_animation_backwards");
			setTimeout(() => {
				this.alerts = this.alerts.filter(x => x !== alert);
				this.renderer.removeClass(e.target, "slide_animation_backwards");
			}, 300);
			return;
		} else if (child) {
			let p = e.currentTarget.parentElement;
			this.renderer.addClass(p, "slide_animation_backwards");
			setTimeout(() => {
				this.alerts = this.alerts.filter(x => x !== alert);
				this.renderer.removeClass(p, "slide_animation_backwards");
			}, 300);
		} else {
			this.alerts = this.alerts.filter(x => x !== alert);
		}
	}
	
	public cssClass(alert: Alert): any {
		if (!alert) {
			return;
		}
		// return css class based on alert type
		switch (alert.type) {
			case AlertType.Success:
				return "alert alert-success";
			case AlertType.Error:
				return "alert alert-danger";
			case AlertType.Info:
				return "alert alert-info";
			case AlertType.Warning:
				return "alert alert-warning";
		}
	}
}
