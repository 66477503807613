<div class="container">
  <div class="row">
    <div class="main-content col-12">
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th class="node_id_h">id</th>
            <th class="question_h">{{ 'Decision_tree.Question' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of treeTableView" id="{{i.id}}">
            <td>{{i.id}}</td>
            <td>
              <span class="question-indicator">
                <span *ngIf="!i.tree_end || i.tree_end === -1">Q:</span>
                <span *ngIf="i.tree_end && i.tree_end !== -1">Result:</span>
              </span>
              <span class="question-name">{{i.description}}</span>
              <div class="answer-badges">
                <div class="badge badge-primary" *ngFor="let q of i.answers">
                  <span class="answer-name">
                    <span>{{q.description}} </span>
                    <span *ngFor="let i of q.attributes">[ {{i.description_to_show}} ] </span>
                  </span>
                  <i class="fas fa-arrow-right"></i>
                  <a (click)="goTo(q.next_question_id)" class="answer-leads-to" [ngClass]="{'next_node_error': !q.next_question_id}">
                    <span *ngIf="!q.next_question_id">error</span>
                    <span *ngIf="q.next_question_id">#{{q.next_question_id}}</span>
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
