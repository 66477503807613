<div class="create-tax-advisors-page-tpl container">
  <div class="row">
    <div class="main-content col-12">
      <div class="top-bar">
        <div class="breadcrumbs">
            <span class="breadcrumb-item">
              <a [routerLink]="['/taxadvisors']">{{ 'Shared.Tax_advisors' | translate }}</a>
            </span>
          <span class="breadcrumb-item">{{ 'Create_edit_tax_advisor.New_tax_advisor' | translate }}</span>
        </div>
        <div class="controls">
          <button class="submit-form-btn no-icon-btn" form="createTaxAdvisorForm">
            <span>{{ 'Shared.Save' | translate }}</span>
          </button>
          <button class="cancel-form-btn no-icon-btn" routerLink="/taxadvisors" type="button">
            <span>{{ 'Shared.Cancel' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="form-wrapper">

        <form name="createTaxAdvisorForm" id="createTaxAdvisorForm" class="createTaxAdvisorForm col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" [formGroup]="taxAdvisorForm" (ngSubmit)="taxAdvisorForm.valid && createTaxAdvisor()" #f="ngForm" [ngClass]="{ 'ng-submitted-error': f.submitted && f.form.invalid }" novalidate>

          <div class="form-group">
            <div class="input-wrapper name">
              <label for="name">{{ 'Create_edit_tax_advisor.Name' | translate }}</label>
              <input class="form-control" name="name" id="name" formControlName="name"/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !!taxAdvisorForm.controls.name.errors || !!taxAdvisorForm.controls.name.errors && (!!taxAdvisorForm.controls.name.dirty || !!taxAdvisorForm.controls.name.touched)">
              <p *ngIf="f.submitted && !!taxAdvisorForm.controls.name.errors.required || !!taxAdvisorForm.controls.name.errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="!!taxAdvisorForm.controls.name.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
            </div>
          </div>

          <div class="form-group">
            <div class="input-wrapper legal_name">
              <label for="legal_name">{{ 'Create_edit_tax_advisor.Legal_name' | translate }}</label>
              <input class="form-control" name="legal_name" id="legal_name" formControlName="legal_name"/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !!taxAdvisorForm.controls.legal_name.errors || !!taxAdvisorForm.controls.legal_name.errors && (!!taxAdvisorForm.controls.legal_name.dirty || !!taxAdvisorForm.controls.legal_name.touched)">
              <p *ngIf="f.submitted && !!taxAdvisorForm.controls.legal_name.errors.required || !!taxAdvisorForm.controls.legal_name.errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="!!taxAdvisorForm.controls.legal_name.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
            </div>
          </div>

          <div class="form-group">
            <div class="input-wrapper contact_person">
              <label for="contact_person">{{ 'Create_edit_tax_advisor.Contact_person' | translate }}</label>
              <input class="form-control" name="contact_person" id="contact_person" formControlName="contact_person"/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !!taxAdvisorForm.controls.contact_person.errors || !!taxAdvisorForm.controls.contact_person.errors && (!!taxAdvisorForm.controls.contact_person.dirty || !!taxAdvisorForm.controls.contact_person.touched)">
              <p *ngIf="f.submitted && !!taxAdvisorForm.controls.contact_person.errors.required || !!taxAdvisorForm.controls.contact_person.errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="!!taxAdvisorForm.controls.contact_person.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
            </div>
          </div>

          <div class="form-group">
            <div class="input-wrapper email">
              <label for="email">{{ 'Create_edit_tax_advisor.Email' | translate }}</label>
              <input type="email" class="form-control" name="email" id="email" formControlName="email"/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !!taxAdvisorForm.controls.email.errors || !!taxAdvisorForm.controls.email.errors && (!!taxAdvisorForm.controls.email.dirty || !!taxAdvisorForm.controls.email.touched)">
              <p *ngIf="f.submitted && !!taxAdvisorForm.controls.email.errors.required || !!taxAdvisorForm.controls.email.errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="f.submitted && !!taxAdvisorForm.controls.email.errors.pattern">{{'Shared.Email_isnt_valid' | translate}}</p>
              <p *ngIf="!!taxAdvisorForm.controls.email.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
            </div>
          </div>

          <div class="form-group">
            <div class="input-wrapper company_details">
              <label for="company_details">{{ 'Create_edit_tax_advisor.Company_details' | translate }}</label>
              <textarea class="form-control" name="company_details" id="company_details" formControlName="company_details"></textarea>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !!taxAdvisorForm.controls.company_details.errors || !!taxAdvisorForm.controls.company_details.errors && (!!taxAdvisorForm.controls.company_details.dirty || !!taxAdvisorForm.controls.company_details.touched)">
              <p *ngIf="f.submitted && !!taxAdvisorForm.controls.company_details.errors.required || !!taxAdvisorForm.controls.company_details.errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="!!taxAdvisorForm.controls.company_details.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
            </div>
          </div>

          <div class="form-group">
            <div class="input-wrapper phone_number">
              <label for="phone_number">{{ 'Create_edit_tax_advisor.Phone_number' | translate }}</label>
              <input type="tel" class="form-control" name="phone_number" id="phone_number" formControlName="phone_number"/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !!taxAdvisorForm.controls.phone_number.errors || !!taxAdvisorForm.controls.phone_number.errors && (!!taxAdvisorForm.controls.phone_number.dirty || !!taxAdvisorForm.controls.phone_number.touched)">
              <p *ngIf="f.submitted && !!taxAdvisorForm.controls.phone_number.errors.required || !!taxAdvisorForm.controls.phone_number.errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="!!taxAdvisorForm.controls.phone_number.errors.pattern">{{'Shared.Phone_number_is_not_valid' | translate}}</p>
            </div>
          </div>

        </form>

        <!--<p>Form value: {{taxAdvisorForm.value | json}}</p>
        <p>Form value: {{taxAdvisorForm.status | json}}</p>-->

        <!-- <form name="createTaxAdvisorForm" id="createTaxAdvisorForm" class="createTaxAdvisorForm col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" (ngSubmit)="f.form.valid && createTaxAdvisor()" #f="ngForm" novalidate>

           <div class="form-group" [ngClass]="{ 'has-error': f.submitted && f.form.invalid }">
             &lt;!&ndash;<div class="input-wrapper name">
               <label for="name">{{ 'Create_edit_tax_advisor.Name' | translate }}</label>
               <input class="form-control" name="name" id="name" [(ngModel)]="newTaxAdvisor.name" #name="ngModel" required/>
               <div *ngIf="f.submitted && !name.valid" class="form-error-block help-block">
                 {{'Shared.Field_is_required' | translate}}
               </div>
             </div>&ndash;&gt;

             &lt;!&ndash;<div class="input-wrapper legal_name">
               <label for="legal_name">{{ 'Create_edit_tax_advisor.Legal_name' | translate }}</label>
               <input class="form-control" name="legal_name" id="legal_name" [(ngModel)]="newTaxAdvisor.legal_name" #legal_name="ngModel" required/>
               <div *ngIf="f.submitted && !legal_name.valid" class="form-error-block help-block">
                 {{'Shared.Field_is_required' | translate}}
               </div>
             </div>&ndash;&gt;

             &lt;!&ndash;<div class="input-wrapper contact_person">
               <label for="contact_person">{{ 'Create_edit_tax_advisor.Contact_person' | translate }}</label>
               <input class="form-control" name="contact_person" id="contact_person" [(ngModel)]="newTaxAdvisor.contact_person" #contact_person="ngModel" required/>
               <div *ngIf="f.submitted && !contact_person.valid" class="form-error-block help-block">
                 {{'Shared.Field_is_required' | translate}}
               </div>
             </div>&ndash;&gt;

             &lt;!&ndash;<div class="input-wrapper email">
               <label for="email">{{ 'Create_edit_tax_advisor.Email' | translate }}</label>
               <input type="email" class="form-control" name="email" id="email" [(ngModel)]="newTaxAdvisor.email" #email="ngModel" required/>
               <div *ngIf="f.submitted && !email.valid" class="form-error-block help-block">
                 {{'Shared.Field_is_required' | translate}}
               </div>
             </div>&ndash;&gt;

             &lt;!&ndash;<div class="input-wrapper address">
               <label for="address">{{ 'Create_edit_tax_advisor.Address' | translate }}</label>
               <textarea class="form-control" name="address" id="address" [(ngModel)]="newTaxAdvisor.address" #address="ngModel" required></textarea>
               <div *ngIf="f.submitted && !address.valid" class="form-error-block help-block">
                 {{'Shared.Field_is_required' | translate}}
               </div>
             </div>&ndash;&gt;

             &lt;!&ndash;<div class="input-wrapper phones">
               <label for="phones">{{ 'Create_edit_tax_advisor.Phone' | translate }}</label>
               <input type="tel" class="form-control" name="phones" id="phones" [(ngModel)]="newTaxAdvisor.phones" #phones="ngModel" required/>
               <div *ngIf="f.submitted && !phones.valid" class="form-error-block help-block">
                 {{'Shared.Field_is_required' | translate}}
               </div>
             </div>&ndash;&gt;
           </div>
         </form>-->
      </div>
    </div>
  </div>
</div>
