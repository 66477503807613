import {Component, EventEmitter, Output, ViewChild, HostListener, OnInit} from "@angular/core";
import {Node} from "../../_models/index";
import {DecisionTreeService, AlertService, SettingsService, SharedService} from "../../_services/index";
import {TreeZoomDirective} from "../../_directives/index";

@Component({
	selector: "app-decision-tree-visualization",
	templateUrl: "./decision-tree-visualization.component.html",
	styleUrls: ["./decision-tree-visualization.component.less"]
})
export class DecisionTreeVisualizationComponent implements OnInit {
	@ViewChild(TreeZoomDirective) TreeZoomDirective;
	
	lang: any = {active: localStorage.getItem("lang")};
	
	treeWasChanged = false;
	unique_id = 1;
	QAList: Node;
	availableAttributes: any;
	wrappedNodes: any[] = [];
	
	scale;
	
	constructor(
		public _AlertService: AlertService,
		public _DecisionTreeService: DecisionTreeService,
		public _SettingsService: SettingsService,
		public _SharedService: SharedService
	) {
		this.wrappedNodes = JSON.parse(localStorage.getItem("wrappedNodes"));
		if (!this.wrappedNodes) {
			localStorage.setItem("wrappedNodes", JSON.stringify([]));
			this.wrappedNodes = [];
		}
		this.getAttributes();
		this.getTree(false, this.wrappedNodes);
	}
	
	ngOnInit() {
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang.active = res;
			this.attributesLangHandler();
			this.getUniqueId(this.QAList, this.wrappedNodes);
		});
	}
	
	displayScale(e): void {
		this.scale = e;
	}
	
	zoomIn(): void {
		this.TreeZoomDirective.zoomIn();
	}
	
	zoomOut(): void {
		this.TreeZoomDirective.zoomOut();
	}
	
	resetZoom(): void {
		this.TreeZoomDirective.resetZoom();
	}
	
	public attributesLangHandler(): void {
		this.availableAttributes.forEach(i => {
			if (this.lang.active === "en") {
				i["description_to_show"] = i["description"];
			} else {
				for (let key of Object.keys(i["description_translation"])) {
					if (key.toLowerCase() === this.lang.active) {
						if (i["description_translation"][key] !== null) {
							i["description_to_show"] = i["description_translation"][key];
						} else {
							i["description_to_show"] = i["description"];
						}
					}
				}
			}
		});
	}
	
	public changesWereMade(val: boolean) {
		this.treeWasChanged = val;
	}
	
	public getAttributes(): void {
		this._SettingsService.getAttributes()
		.subscribe(res => {
			this.availableAttributes = res.result;
			this.attributesLangHandler();
		}, err => {
			console.log(err);
		});
	}
	
	public getTree(cancelingChanges?, wrappedNodes?): void {
		this._DecisionTreeService.getDecisionTree()
		.subscribe(res => {
			this.QAList = res.result;
			this.getUniqueId(this.QAList, wrappedNodes);
			if (cancelingChanges) {
				this._AlertService.success("Alert.You_successfully_reverted_all_the_latest_changes");
				this.changesWereMade(false);
			}
		}, err => {
			console.log(err);
		});
	}
	
	public getUniqueId(QAList, wrappedNodes): void {
		let collapsedItem = function (id) {
			for (let i = 0; i < wrappedNodes.length; i++) {
				if (wrappedNodes[i] === id) {
					return true;
				}
			}
			return false;
		};
		let t = this.unique_id;
		
		let rq = function (q, availableAttributes): void {
			for (let i = 0; i < q.children.length; i++) {
				(collapsedItem(q.children[i]["id"])) ? q.children[i]["collapsed"] = true : q.children[i]["collapsed"] = false;
				
				q.children[i].attributes.forEach(obj => {
					for (let y = 0; y < availableAttributes.length; y++) {
						if (obj["id"] === availableAttributes[y].id) {
							obj["description_to_show"] = availableAttributes[y]["description_to_show"];
							obj["description_translation"] = availableAttributes[y]["description_translation"];
							break;
						}
					}
				});
				if (q.children[i].id > t) {
					t = q.children[i].id;
				}
				if (q.children[i].children.length > 0) {
					rq(q.children[i], availableAttributes);
				}
			}
		};
		for (let i = 0; i < QAList.children.length; i++) {
			(collapsedItem(QAList.children[i]["id"])) ? QAList.children[i]["collapsed"] = true : QAList.children[i]["collapsed"] = false;
			
			QAList.children[i].attributes.forEach(obj => {
				for (let y = 0; y < this.availableAttributes.length; y++) {
					if (obj["id"] === this.availableAttributes[y].id) {
						obj["description_to_show"] = this.availableAttributes[y]["description_to_show"];
						obj["description_translation"] = this.availableAttributes[y]["description_translation"];
						break;
					}
				}
			});
			if (t < QAList.children[i].id) {
				t = QAList.children[i].id;
			}
			rq(QAList.children[i], this.availableAttributes);
		}
		localStorage.setItem("latest_id", t.toString());
	}
	
	public getUniqueIdQ(QAList): any {
		let t = this.unique_id;
		let rq = function (q): void {
			for (let i = 0; i < q.children.length; i++) {
				if (q.children[i].id > t) {
					t = q.children[i].id;
				}
				if (q.children[i].children.length > 0) {
					rq(q.children[i]);
				}
			}
		};
		for (let i = 0; i < QAList.children.length; i++) {
			if (t < QAList.children[i].id) {
				t = QAList.children[i].id;
			}
			rq(QAList.children[i]);
		}
		localStorage.setItem("latest_id", t.toString());
		return t;
	}
	
	public deleteNode(QAList, item): void {
		if (item["deleteOption"] === 2) {
			let rq = function (q): void {
				for (let i = 0; i < q.children.length; i++) {
					if (item.parent_id === q.children[i].id) {
						for (let w = 0; w < q.children[i].children.length; w++) {
							if (q.children[i].children[w].id === item.id) {
								let index = q.children[i].children.indexOf(q.children[i].children[w]);
								if (index !== -1) {
									q.children[i].children.splice(index, 1);
								}
							}
						}
						break;
					}
					if (q.children[i].children.length > 0) {
						rq(q.children[i]);
					}
				}
			};
			for (let i = 0; i < QAList.children.length; i++) {
				if (this.unique_id < QAList.children[i].id) {
					this.unique_id = QAList.children[i].id;
				}
				if (item.parent_id === QAList.children[i].id) {
					let index = QAList.children[i].children.indexOf(item);
					if (index !== -1) {
						QAList.children[i].children.splice(index, 1);
					}
					break;
				}
				if (item.parent_id === 1) {
					let index1 = QAList.children.indexOf(item);
					if (index1 !== -1) {
						QAList.children.splice(index1, 1);
					}
					break;
				}
				rq(QAList.children[i]);
			}
		} else if (item["deleteOption"] === 1) {
			let temp = item["children"][0]["children"][0];
			let rq = function (q): void {
				for (let i = 0; i < q.children.length; i++) {
					if (item.parent_id === q.children[i].id) {
						for (let w = 0; w < q.children[i].children.length; w++) {
							if (q.children[i].children[w].id === item.id) {
								let index = q.children[i].children.indexOf(q.children[i].children[w]);
								if (index !== -1) {
									q.children[i].children.splice(index, 1, temp);
								}
							}
						}
						break;
					}
					if (q.children[i].children.length > 0) {
						rq(q.children[i]);
					}
				}
			};
			
			for (let i = 0; i < QAList.children.length; i++) {
				if (this.unique_id < QAList.children[i].id) {
					this.unique_id = QAList.children[i].id;
				}
				if (item.parent_id === QAList.children[i].id) {
					let index = QAList.children[i].children.indexOf(item);
					if (index !== -1) {
						QAList.children[i].children.splice(index, 1, temp);
					}
					break;
				}
				if (item.parent_id === 1) {
					let index1 = QAList.children.indexOf(item);
					if (index1 !== -1) {
						QAList.children.splice(index1, 1, temp);
					}
					break;
				}
				rq(QAList.children[i]);
			}
			
		}
	}
	
	/* IS USED TO CLEAN UP THE UNFINISHED BRANCHES WITH 'NEXT_QUESTION_ID' */
	public recursiveCycle(QAList): void {
		let rq = function (q): void {
			for (let i = 0; i < q.children.length; i++) {
				if (q.children[i].children.length === 0) {
					q.children[i].next_question_id = null;
				}
				if (q.children[i].children.length > 0) {
					rq(q.children[i]);
				}
			}
		};
		for (let i = 0; i < QAList.children.length; i++) {
			rq(QAList.children[i]);
		}
	}
	public recursiveCycleWrappedAll(QAList): void {
		let wrappedNodes = [];
		let rq = function (q): void {
			for (let i = 0; i < q.children.length; i++) {
				q.children[i].collapsed = true;
				wrappedNodes.push(q.children[i]["id"]);
				if (q.children[i].children.length === 0) {
					q.children[i].next_question_id = null;
				}
				if (q.children[i].children.length > 0) {
					rq(q.children[i]);
				}
			}
		};
		for (let i = 0; i < QAList.children.length; i++) {
			QAList.children[i].collapsed = true;
			wrappedNodes.push(QAList.children[i]["id"]);
			rq(QAList.children[i]);
		}
		this.wrappedNodes = wrappedNodes;
		localStorage.setItem('wrappedNodes', JSON.stringify(wrappedNodes));
	}
	
	public receiveMessage($event) {
		this.deleteNode(this.QAList, $event);
	}
	
	public receiveMessageAboutChanges() {
		this.changesWereMade(true);
	}
	
	public receiveMessageDuplicate($event) {
		let uniqueId = this.getUniqueIdQ(this.QAList);
		
		// Handling ids, parent_ids, next_question_ids in the lower level nodes
		let rqDuplicate = function (q, last_id): void {
			// let l_id = last_id;
			for (let i = 0; i < q.children.length; i++) {
				uniqueId = uniqueId + 1;
				q.children[i]["parent_id"] = q.id;
				q.children[i]["id"] = uniqueId;
				if (q.children[i].type === "answer" && q.children[i].children.length) {
					q.children[i]["next_question_id"] = uniqueId + 1;
				}
				if (q.children[i].children.length > 0) {
					rqDuplicate(q.children[i], uniqueId);
				}
			}
		};
		
		// Lower nodes handler
		let rq = function (q, last_id): void {
			// let l_id = uniqueId;
			for (let i = 0; i < q.children.length; i++) {
				if (q.children[i].id === $event.parent_id) {
					let index = q.children[i].children.indexOf($event);
					let obj = JSON.parse(JSON.stringify($event));
					uniqueId = uniqueId + 1;
					obj["id"] = uniqueId;
					if (obj.type === "answer" && obj.children.length) {
						obj["next_question_id"] = uniqueId + 1;
					}
					q.children[i].children.splice(index + 1, 0, obj);
					rqDuplicate(q.children[i].children[index + 1], uniqueId);
				}
				if (q.children[i].children.length > 0) {
					rq(q.children[i], uniqueId);
				}
			}
		};
		
		// Top level nodes handler
		for (let i = 0; i < this.QAList.children.length; i++) {
			if ($event.parent_id === 1) {
				let index = this.QAList.children.indexOf($event);
				let obj = JSON.parse(JSON.stringify($event));
				uniqueId = uniqueId + 1;
				obj["parent_id"] = this.QAList.id;
				obj["id"] = uniqueId;
				if (obj.type === "answer" && obj.children.length) {
					obj["next_question_id"] = obj["id"] + 1;
				}
				this.QAList.children.splice(index + 1, 0, obj);
				rqDuplicate(this.QAList.children[index + 1], obj["id"]);
				break;
			}
			rq(this.QAList.children[i], this.getUniqueIdQ(this.QAList));
		}
		
		this.getUniqueIdQ(this.QAList);
	}
	
	public receiveMessageCollapse($event) {
		console.log($event);
		function contains(arr) {
			for (let i = 0; i < arr.length; i++) {
				if (arr[i] === $event["id"]) {
					return i + 1;
				}
			}
			return null;
		}
		if (contains(this.wrappedNodes)) {
			let index = contains(this.wrappedNodes);
			this.wrappedNodes["splice"](index - 1 || 0, 1);
		} else {
			this.wrappedNodes.push($event["id"]);
		}
		localStorage.setItem("wrappedNodes", JSON.stringify(this.wrappedNodes));
	}
	
	public importDecisionTree(): void {
		this.recursiveCycle(this.QAList);
		this._DecisionTreeService.importDecisionTree(this.QAList)
		.subscribe(res => {
			this._AlertService.success("Alert.You_successfully_saved_all_changes");
			this.changesWereMade(false);
		}, err => {
			console.log(err);
		});
	}
	
	public wrapAll(): void {
		localStorage.setItem("wrappedNodes", JSON.stringify([]));
		this.wrappedNodes = [];
		this.recursiveCycleWrappedAll(this.QAList);
	}
	
}
