import {Component, OnInit} from "@angular/core";
import {AlertService, SettingsService} from "../../_services";
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';

@Component({
	selector: "app-email-setup",
	templateUrl: "./email-setup.component.html",
	styleUrls: ["./email-setup.component.less"]
})
export class EmailSetupComponent implements OnInit {
	
	emailSetupForm: FormGroup;
	
	rootEmails = [];
	showSaveBtn = false;
	
	constructor(
		private _SettingsService: SettingsService,
		private _AlertService: AlertService,
		private fb: FormBuilder
	) {
	}
	
	ngOnInit() {
		this.emailSetupFormInit();
		this.getRootEmail();
	}
	
	private getRootEmail(): void {
		this._SettingsService.getRootEmail()
		.subscribe((res) => {
			console.log(res);
			res.result.forEach(res => {
				this.rootEmails.push(res);
			});
			this.emailSetupForm.get('email').setValue(this.rootEmails[0].email);
		}, (err) => {
			console.log(err);
		})
	}
	
	private emailSetupFormInit(): void {
		const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
		this.emailSetupForm = this.fb.group({
			email: [ null, [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)] ]
		});
	}
	
	public submitEmailChange(form): void {
		console.log(form);
		console.log(form.controls.email.value);
		let dataToSend = {
			additional_email_id: this.rootEmails[0].id,
			email_address: form.controls.email.value
		}
		this._SettingsService.addChangeRootEmail(dataToSend)
		.subscribe(res => {
			console.log(res);
			this.showSaveBtn = false;
		}, err => {
			console.log(err);
			this.showSaveBtn = false;
		})
	}
}
