<div class="container edit-category-tpl">
  <div class="row">
    <div class="main-content col-12">
      <div class="top-bar">
        <div class="breadcrumbs">
            <span class="breadcrumb-item">
              <a [routerLink]="['/categories']">{{ 'Shared.Categories_breadcrumb' | translate }}</a>
            </span>
          <span class="breadcrumb-item">{{ 'Create_edit_category.Edit_category_breadcrumb' | translate }}</span>
        </div>
        <div class="controls">
          <button class="submit-form-btn no-icon-btn" form="editCategoryForm">
            <span>{{ 'Shared.Save' | translate }}</span>
          </button>
          <button class="cancel-form-btn no-icon-btn" routerLink="/categories" type="button">
            <span>{{ 'Shared.Cancel' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="form-wrapper">
        <form id="editCategoryForm" name="editCategoryForm" class="editCategoryForm col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" (ngSubmit)="f.form.valid && editCategory(categoryData)" #f="ngForm" novalidate>

          <div class="form-group required_section" [ngClass]="{ 'has-error': f.submitted && !title_EN.valid }">

            <div class="input-wrapper title">
              <label for="title">{{ 'Shared.Title' | translate }} (EN) <span class="required_asterisk">*</span></label>
              <input class="form-control" name="title" id="title" [(ngModel)]="categoryData.title" #title_EN="ngModel" required/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !title_EN.valid">
              <p *ngIf="f.submitted && !title_EN.valid">{{'Shared.Field_is_required' | translate}}</p>
            </div>

            <div class="input-wrapper description">
              <label for="description">{{ 'Shared.Description' | translate }} (EN)</label>
              <textarea (change)="onDataChange('descr', 'EN', categoryData.description)" class="form-control" name="description" id="description" [(ngModel)]="categoryData.description" #description_EN="ngModel"></textarea>
            </div>
          </div>

          <div class="form-group" *ngFor="let q of translations; let i = index">
            <div *ngFor="let key of objectKeys(q)">
              <div class="input-wrapper title">
                <label for="{{key}}_title">{{ 'Shared.Title' | translate }} ({{key}})</label>
                <input (change)="onDataChange('title', key, q[key].title)" class="form-control" name="{{key}}_title" id="{{key}}_title" [(ngModel)]="q[key].title" #title="ngModel"/>
              </div>

              <div class="input-wrapper description">
                <label for="{{key}}_descr">{{ 'Shared.Description' | translate }} ({{key}})</label>
                <textarea (change)="onDataChange('descr', key, q[key].descr)" class="form-control" name="{{key}}_descr" id="{{key}}_descr" [(ngModel)]="q[key].descr" #description="ngModel"></textarea>
              </div>
            </div>
          </div>

        </form>

        <form class="uploadPhotoForm col-12 col-sm-12 col-md-3 col-lg-5 col-xl-5" name="uploadPhotoForm" id="uploadPhotoForm" [formGroup]="uploadPhotoForm"  #f1="ngForm" (ngSubmit)="uploadPhotoForm.valid" novalidate>

          <img class="category_image" [src]="categoryData.image_path || imagePlaceholder" [alt]="categoryData.image_path || imagePlaceholder">

          <input accept="image/*" (change)="handleImageInput($event.target.files)" formControlName="image" type="file">
          <button *ngIf="categoryData.image_path" class="cancel-form-btn no-icon-btn" type="button" (click)="deletePhoto()">
            <span>{{ 'Create_edit_category.Delete_image_btn' | translate }}</span>
          </button>

        </form>

      </div>
    </div>
  </div>
</div>
