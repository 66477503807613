import { Component } from '@angular/core';
import { fadeAnimation } from "./_animations/fade.animation";
import { AlertService } from "./_services/index";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [fadeAnimation]
})
export class AppComponent {
  lang: string = localStorage.getItem('lang');
  currentRoute: string;
  
  constructor(
    private alertService: AlertService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.lang ? translate.use(this.lang) : translate.use('ru');
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.translate.use(event.lang);
        localStorage.setItem('lang', event.lang);
      });
    
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.currentRoute = e.url;
      }
    });
  }
  
  error(message: string) {
    this.alertService.error(message);
  }
}
