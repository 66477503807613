import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AlertService } from "../../_services/alert.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TaxAdvisorsService } from "../../_services/taxadvisors.service";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-single-taxadvisor',
  templateUrl: './single-taxadvisor.component.html',
  styleUrls: ['./single-taxadvisor.component.less']
})
export class SingleTaxAdvisorComponent implements OnInit, AfterViewInit {
  taxAdvisorId = this.route.snapshot.params['id'];
  taxAdvisor;
  taxAdvisorManagers: any[];
  tab = 1;
  
  pConfig1 = {
    itemsPerPage: 50,
    currentPage: 1,
    sortField: '',
    reverseOrder: false,
    search: ''
  };
  
  constructor(
    private _alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private _taxAdvisorsService: TaxAdvisorsService,
    private translate: TranslateService
  ) {
    this.taxAdvisorData();
    this.taxAdvisorManagersList();
  }
  
  ngOnInit() {}
  
  ngAfterViewInit() {}
  
  sortBy1 = function(sortField) {
    this.pConfig1['reverseOrder'] = (this.pConfig1['sortField'] === sortField) ? !this.pConfig1['reverseOrder'] : false;
    this.pConfig1['sortField'] = sortField;
  };
  pageChange1(event: any): void {
    this.pConfig1['currentPage'] = event;
  }
  tabHandler(pageId: number): void {
      this.tab = pageId;
  }
  
  getTaxAdvisorData(): Observable<any> {
    return this._taxAdvisorsService.getSingleTaxAdvisorData({
      id: this.taxAdvisorId
    });
  }
  getTaxAdvisorsManagersList(): Observable<any> {
    return this._taxAdvisorsService.getSingleTaxAdvisorManagersList({
      id: this.taxAdvisorId
    });
  }
  
  taxAdvisorData(): void {
    this.getTaxAdvisorData().subscribe(res => {
      this.taxAdvisor = res['tax_adviser'];
      localStorage.setItem('back_url', this.router.url);
    }, error => {
      console.log(error);
    });
  }
  
  taxAdvisorManagersList(): void {
    this.getTaxAdvisorsManagersList().subscribe(res => {
      this.taxAdvisorManagers = res['manager'];
      this.taxAdvisorManagers.forEach(key => {
        if (key.phone_number === null) {
          key.phone_number = '';
        }
      });
    }, error => {
      console.log(error);
    });
  }
  
  public editTaxAdvisorInfo(): void {
    this.router.navigate([`taxadvisors/${this.taxAdvisorId}/edit`]);
  }
  public addTaxAdvisorManager(): void {
    this.router.navigate([`taxadvisors/${this.taxAdvisorId}/manager/new`]);
  }
  public editTaxAdvisorManager(i): void {
    this.router.navigate([`taxadvisors/${this.taxAdvisorId}/manager/${i.id}/edit`]);
  }
  public goToSingleTaxAdvisorManager(i): void {
    this.router.navigate([`taxadvisors/${this.taxAdvisorId}/manager/${i.id}`]);
  }
  public deleteTaxAdvisorManager(i): void {
    let translated_msg;
    if (i.is_active) {
      this.translate.get('Alert.Are_you_sure_you_want_to_deactivate_specialist')
        .subscribe((res: Object) => {
          translated_msg = res;
          let q = confirm(translated_msg);
          if (q) {
            this._taxAdvisorsService.deleteTaxAdvisorManager(i)
              .subscribe(response => {
                this.taxAdvisorManagersList();
              }, err => {
                console.log(err);
              });
          }
        });
    } else {
      this.translate.get('Alert.Are_you_sure_you_want_to_activate_specialist')
        .subscribe((res: Object) => {
          translated_msg = res;
          let q = confirm(translated_msg);
          if (q) {
            this._taxAdvisorsService.deleteTaxAdvisorManager(i)
              .subscribe(response => {
                this.taxAdvisorManagersList();
              }, err => {
                console.log(err);
              });
          }
        });
    }
  }
}
