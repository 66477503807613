import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
// import { environment } from "../../environments/environment";
import { AlertService } from "./alert.service";
import { EnvService } from "./env.service";

@Injectable()
export class CategoryService {
  // private API_BASE = environment.apiBase;
  USER: any;
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  constructor(
    private env: EnvService,
    private http: HttpClient,
    private alertService: AlertService
  ) {
    this.getUserData();
  }
  
  public getCategoriesAll(): Observable<any> {
    const url = `${this.env.apiBase}/category`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  public getCategoriesAllByUser(data): Observable<any> {
    const url = `${this.env.apiBase}/category`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('user_id', data.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public getSingleCategory(category_id): Observable<any> {
    const url = `${this.env.apiBase}/category`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('category_id', category_id);
    return this.http.get<Object>(url, {params});
  }
  
  public getSingleSubcategory(subcategory_id): Observable<any> {
    const url = `${this.env.apiBase}/subcategory`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('subcategory_id', subcategory_id);
    return this.http.get<Object>(url, {params});
  }
  
  public createNewCategory(category_data): Observable<any> {
    const url = `${this.env.apiBase}/category`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post<Object>(url, category_data, {params});
  }
  
  public uploadPhoto(category_id, file?, delete_image?: boolean): Observable<any> {
    const url = `${this.env.apiBase}/category/image`;
    console.log(file);
    let formData: FormData = new FormData();
    if (file) {
      formData.append('img_file', file, file.name);
    }
    console.log(formData);
    
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('category_id', category_id);
    
    return this.http.post(url,  formData, {params});
  }
  
  public createNewSubcategory(subcategory_data): Observable<any> {
    const url = `${this.env.apiBase}/subcategory`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post<Object>(url, subcategory_data, {params});
  }
  
  public editSingleCategory(category_data): Observable<any> {
    const url = `${this.env.apiBase}/category`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post<Observable<any>>(url, category_data, {params});
  }
  
  public editSingleSubcategory(subcategory_data): Observable<any> {
    const url = `${this.env.apiBase}/subcategory`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post(url, subcategory_data, {params});
  }
  
  
  
  public deactivateCategory(id): Observable<any> {
    const url = `${this.env.apiBase}/category`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post(url, {
      is_active: 0,
      category_id: id
    }, {params});
  }
  
  public deactivateSubcategory(id): Observable<any> {
    const url = `${this.env.apiBase}/subcategory`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post(url, {
      is_active: 0,
      subcategory_id: id
    }, {params});
  }
  
  public deleteCategory(id): Observable<any> {
    const url = `${this.env.apiBase}/category`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('is_active', '0')
      .append('category_id', id);
    return this.http.delete(url, {
      params: params
    });
  }
  
  public deleteSubcategory(id): Observable<any> {
    const url = `${this.env.apiBase}/subcategory`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('is_active', '0')
      .append('subcategory_id', id);
    return this.http.delete(url, {
      params: params
    });
  }
  
  
  public getQuestionnaires(): Observable<any> {
    const url = `${this.env.apiBase}/form`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  public getQuestionnairesByUser(data): Observable<any> {
    const url = `${this.env.apiBase}/user/form`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('user_id', data.user_id);
    return this.http.get<Object>(url, {params});
  }
  public editQuestionnairesByUser(data): Observable<any> {
    const url = `${this.env.apiBase}/user/form`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post<Object>(url, data, {params});
  }
  public getSingleQuestionnaire(data): Observable<any> {
    const url = `${this.env.apiBase}/form`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('form_id', data.form_id);
    return this.http.get<Object>(url, {params});
  }
  public createNewQuestionnaire(q_data): Observable<any> {
    const url = `${this.env.apiBase}/form`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post<Object>(url, [q_data], {params});
  }
  public editQuestionnaire(data): Observable<any> {
    const url = `${this.env.apiBase}/form`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post<Object>(url, [data], {params});
  }
  public deleteQuestionnaire(data): Observable<any> {
    const url = `${this.env.apiBase}/form`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('ids', data.ids);
    return this.http.delete(url, {
      params: params
    });
  }

}
