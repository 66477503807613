<div class="tax-advisors-managers-page-tpl container">
	<div class="row">
		<div class="main-content col-12">
			
			<div class="top-bar">
				<div class="breadcrumbs">
					<span class="breadcrumb-item">
						<a [routerLink]="['/taxadvisors']">{{ 'Shared.Tax_advisors' | translate }}</a>
					</span>
					<span class="breadcrumb-item" *ngIf="taxAdvisorManagerData">
						<a [routerLink]="['/taxadvisors/' + taxAdvisorId]">
							{{taxAdvisorManagerData.tax_advisers_name}}
						</a>
					</span>
					<span class="breadcrumb-item" *ngIf="taxAdvisorManagerData">
						{{taxAdvisorManagerData.name}}
						<i [ngClass]="{'active': taxAdvisorManagerData.is_active === 1}" class="fas fa-circle"></i>
					</span>
				</div>
				<div class="controls">
					<button class="main-action-btn no-icon-btn" *ngIf="tab === 1" (click)="editTaxAdvisorManager()">
						<span>{{ 'Tax_advisors.Edit_information' | translate }}</span>
					</button>
				</div>
			</div>
			
			<div class="vertical-tabs-wrapper-unified">
				<div class="sidebar vertical-tabs">
					<ul class="tab-navigation">
						<li [class.active]="tab === 1">
							<a (click)="tabHandler(1)" target="_self">
							<span class="tax-advisors-manager-tab-title">
							  {{ 'Tax_advisors.General_information' | translate }}
							</span>
							</a>
						</li>
						<li [class.active]="tab === 2">
							<a (click)="tabHandler(2)" target="_self">
							<span class="tax-advisors-manager-time-tracker-tab-title">
								{{ 'Tax_advisors.Manager_time_tracking_tab' | translate }}
							</span>
							</a>
						</li>
						<li [class.active]="tab === 3">
							<a (click)="tabHandler(3)" target="_self">
								<span class="tax-advisors-action-logs-tab-title">
									{{ 'Tax_advisors.Manager_action_logs_tab' | translate }}
								</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="tab-content">
					<div id="tax-advisors-manager" class="tab" [class.active]="tab === 1">
						<div class="tax-advisors-manager-info-wrapper" *ngIf="taxAdvisorManagerData">
							<table class="tax-advisors-manager-info-table">
								<tbody>
								<tr>
									<td class="td-title">{{ 'Tax_advisors.Manager_name' | translate }}:</td>
									<td class="td-content">{{taxAdvisorManagerData.name}}</td>
								</tr>
								<tr>
									<td class="td-title">{{ 'Tax_advisors.Manager_email' | translate }}:</td>
									<td class="td-content">{{taxAdvisorManagerData.email}}</td>
								</tr>
								<tr>
									<td class="td-title">{{ 'Tax_advisors.Manager_phone' | translate }}:</td>
									<td class="td-content">{{taxAdvisorManagerData.phone_number}}</td>
								</tr>
								<tr>
									<td class="td-title">{{ 'Tax_advisors.Manager_created_at' | translate }}:</td>
									<td class="td-content">{{taxAdvisorManagerData.created_at}}</td>
								</tr>
								<tr>
									<td class="td-title">{{ 'Tax_advisors.Manager_updated_at' | translate }}:</td>
									<td class="td-content">{{taxAdvisorManagerData.updated_at}}</td>
								</tr>
								</tbody>
							</table>
							<div class="tax-advisors-manager-actions">
								<div *permission="['operator']" class="rate-setup">
									<div class="current_rate_wrapper" *ngIf="!taxAdvisorManagerData.rate_edit">
										<div class="current_rate">
											<p>{{'Tax_advisors.Current_rate' | translate}}</p>
											<p translate [translateParams]="{ rate: taxAdvisorManagerData.rate }">
												Tax_advisors.Current_rate_with_count
											</p>
										</div>
										<button class="main-action-btn no-icon-btn" type="button"
												(click)="taxAdvisorManagerData.rate_edit = true">
											<span>{{ 'Tax_advisors.Change_manager_rate_btn' | translate }}</span>
										</button>
									</div>
									<form class="managerRateForm" *ngIf="taxAdvisorManagerData.rate_edit"
										  name="managerRateForm" id="managerRateForm" [formGroup]="managerRateForm"
										  #f="ngForm" (ngSubmit)="managerRateForm.valid && setupManagerRate()"
										  novalidate>
										<div class="form-group">
											<div class="input-wrapper rate">
												<label for="rate" class="no-text-label">{{'Tax_advisors.Rate_label' |
													translate}}</label>
												<input type="number" class="form-control" name="rate" id="rate"
													   [placeholder]="'Tax_advisors.Enter_manager_rate_placeholder' | translate"
													   formControlName="rate" min="0"/>
											</div>
											<div class="form-control-feedback"
												 *ngIf="f.submitted && !!managerRateForm.controls.rate.errors || !!managerRateForm.controls.rate.errors && (!!managerRateForm.controls.rate.dirty || !!managerRateForm.controls.rate.touched)">
												<p *ngIf="f.submitted && !!managerRateForm.controls.rate.errors.required || !!managerRateForm.controls.rate.errors.required">
													{{'Shared.Field_is_required' | translate}}</p>
												<p *ngIf="!!managerRateForm.controls.rate.errors?.min" translate
												   [translateParams]="{ min_number_value: managerRateForm.controls.rate.errors.min.min }">
													Shared.Min_number_error
												</p>
											</div>
										</div>
										<button class="main-action-btn no-icon-btn" type="submit">
											<span>{{ 'Tax_advisors.Setup_rate_btn' | translate }}</span>
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div id="manager_time_tracking" class="tab" [class.active]="tab === 2">
						<div class="manager-time-tracking-table-wrapper datatable-wrapper">
							
							<div class="total-manager-time-log" *ngIf="managerTimeLogs">
								<p>
									<span translate [translateParams]="{ count: managerTimeLogs.log_total_work }">Tax_advisors.Manager_total_working_time</span>
								</p>
							</div>
							<div class="current_manager_rate" *ngIf="taxAdvisorManagerData">
								<p>
									<span translate>{{ 'Tax_advisors.Current_rate' }}</span>
									<span translate [translateParams]="{ rate: taxAdvisorManagerData.rate }">Tax_advisors.Current_rate_with_count</span>
								</p>
							</div>
							
							<div class="filters">
								<div class="server-side-filters">
									<form class="timeTrackerFiltersForm" name="timeTrackerFiltersForm"
										  id="timeTrackerFiltersForm" [formGroup]="timeTrackerFiltersForm" #f="ngForm"
										  novalidate>
										<div class="input-wrapper date-range-wrapper">
											<label for="date"></label>
											<input type="text" name="date" id="date" class="doInput"
												   [placeholder]="'Mobile_users.Log_date_placeholder' | translate"
												   bsDaterangepicker
												   (bsValueChange)="timeTrackerFiltersFormSubmit($event)"
												   [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD HH:mm', containerClass: 'theme-dark-blue' }"
												   formControlName="dateRange" autocomplete="off"/>
										</div>
										<div class="input-wrapper status-filter-wrapper">
											<label for="status-filter"></label>
											<ng-select [items]="work_statuses"
													   bindLabel="name"
													   bindValue="id"
													   id="status-filter"
													   appendTo=".input-wrapper.status-filter-wrapper"
													   [multiple]="false"
													   [notFoundText]="'Tax_advisors.No_status_found' | translate"
													   [placeholder]="'Tax_advisors.Select_status_placeholder' | translate"
													   [searchable]="false"
													   [closeOnSelect]="true"
													   [hideSelected]="false"
													   [selectableGroup]="false"
													   (change)="timeTrackerFiltersFormSubmit()"
													   formControlName="statusFilter">
												<ng-template ng-option-tmp let-item="item">
													{{item.name}}
												</ng-template>
											</ng-select>
										</div>
									</form>
								</div>
								<div class="search">
									<label class="search-label" for="pConfig2.search">
										<i class="fas fa-search"></i>
									</label>
									<input [placeholder]="'Shared.Type_to_filter' | translate"
										   [(ngModel)]="pConfig2.search" id="pConfig2.search" name='search'
										   class="doInput">
								</div>
								<div class="itemsPerPage">
									<label>{{'Shared.Items_per_page' | translate}}:</label>
									<div class="perPageIndicators">
										<button [ngClass]="{'active': pConfig2.itemsPerPage === 10}"
												(click)="pConfig2.itemsPerPage = 10; pConfig2.currentPage = 0">10
										</button>
										<button [ngClass]="{'active': pConfig2.itemsPerPage === 20}"
												(click)="pConfig2.itemsPerPage = 20; pConfig2.currentPage = 0">20
										</button>
										<button [ngClass]="{'active': pConfig2.itemsPerPage === 50}"
												(click)="pConfig2.itemsPerPage = 50; pConfig2.currentPage = 0">50
										</button>
										<button [ngClass]="{'active': pConfig2.itemsPerPage === 100}"
												(click)="pConfig2.itemsPerPage = 100; pConfig2.currentPage = 0">100
										</button>
									</div>
								</div>
							</div>
							<div class="table-wrapper">
								<table class="table" *ngIf="managerTimeLogs">
									<thead>
									<tr>
										<th class="name" (click)="sortBy2('name_concat')">
											<span> {{ 'Tax_advisors.Mobile_user_name' | translate }}</span>
											<span class="sort_icons" [hidden]="pConfig2.sortField != 'name_concat'">
												<i class="fas fa-sort-down"
												   [class.sort_active]="pConfig2.sortField == 'name_concat' && pConfig2.reverseOrder == true"></i>
												<i class="fas fa-sort-up"
												   [class.sort_active]="pConfig2.sortField == 'name_concat' && pConfig2.reverseOrder == false"></i>
											</span>
										</th>
										<th class="status" (click)="sortBy2('status')">
											<span>{{ 'Tax_advisors.Work_status' | translate }}</span>
											<span class="sort_icons" [hidden]="pConfig2.sortField != 'status'">
												<i class="fas fa-sort-down"
												   [class.sort_active]="pConfig2.sortField == 'status' && pConfig2.reverseOrder == true"></i>
												<i class="fas fa-sort-up"
												   [class.sort_active]="pConfig2.sortField == 'status' && pConfig2.reverseOrder == false"></i>
											</span>
										</th>
										<th class="total_log_time" (click)="sortBy2('total_log_time')">
											<span>{{ 'Tax_advisors.Total_log_time' | translate }}</span>
											<span class="sort_icons" [hidden]="pConfig2.sortField != 'total_log_time'">
												<i class="fas fa-sort-down"
												   [class.sort_active]="pConfig2.sortField == 'total_log_time' && pConfig2.reverseOrder == true"></i>
												<i class="fas fa-sort-up"
												   [class.sort_active]="pConfig2.sortField == 'total_log_time' && pConfig2.reverseOrder == false"></i>
											</span>
										</th>
										<th class="details"></th>
									</tr>
									</thead>
									<tbody *ngFor="let i of managerTimeLogs.users | filter: pConfig2.search :'name_concat,status,total_log_time' | orderBy: pConfig2.sortField:pConfig2.reverseOrder | paginate: {
										id: 'manager_time_tracking_pagination',
										itemsPerPage: pConfig2.itemsPerPage,
										currentPage: pConfig2.currentPage,
										totalItems: pConfig2.totalItems
									   }" class="hover_rows tbody_duplicates">
									<tr class="row_data">
										<td>{{i.name_concat}}</td>
										<td>{{i.status_name | translate}}</td>
										<td>{{i.total_log_time}}</td>
										<td class="action_td show_details"
											(click)="$event.stopPropagation(); (i.total_log_time) ? openDetailedTimelog(detailedTimelog, i) : ''">
											<span *ngIf="!i.show_details && i.total_log_time">
											  {{ 'Tax_advisors.Show_detailed_time_log' | translate }}
											</span>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
							<div class="table-footer" *ngIf="managerTimeLogs">
								<div class="items-total-wrapper">
								  <span translate [translateParams]="{ total_items: pConfig2.totalItems }">
									Shared.Total_items_in_table
								  </span>
								</div>
								<pagination-controls id="manager_time_tracking_pagination"
													 class="table-pagination"
													 (pageChange)="pageChange2($event)"
													 maxSize="5"
													 directionLinks="false"
													 autoHide="true"
													 responsive="false">
								</pagination-controls>
							</div>
						</div>
					</div>
					<div id="manager_action_logs" class="tab" [class.active]="tab === 3">
						<div class="manager-action-logs-table-wrapper">
							<div class="datatable-wrapper">
								<div class="filters no-search-filter ss-filters">
									<div class="server-side-filters">
										<form class="actionLogFiltersForm" name="actionLogFiltersForm"
											  id="actionLogFiltersForm" [formGroup]="actionLogFiltersForm" #f="ngForm"
											  novalidate>
											<div class="input-wrapper date-range-wrapper">
												<label for="date2"></label>
												<input type="text" name="date2" id="date2" class="doInput"
													   [placeholder]="'Mobile_users.Log_date_placeholder' | translate"
													   bsDaterangepicker
													   (bsValueChange)="actionLogFiltersFormSubmit($event)"
													   [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD HH:mm', containerClass: 'theme-dark-blue' }"
													   formControlName="dateRange" autocomplete="off"/>
											</div>
											<div class="input-wrapper user-filter-wrapper" *ngIf="managerTimeLogs">
												<label for="user-filter"></label>
												<ng-select [items]="managerTimeLogs.users"
														   bindLabel="name"
														   bindValue="id"
														   id="user-filter"
														   appendTo=".input-wrapper.user-filter-wrapper"
														   [multiple]="false"
														   [notFoundText]="'Tax_advisors.No_users_found' | translate"
														   [placeholder]="'Tax_advisors.Select_user_placeholder' | translate"
														   [searchable]="false"
														   [closeOnSelect]="true"
														   [hideSelected]="false"
														   [selectableGroup]="false"
														   (change)="actionLogFiltersFormSubmit()"
														   formControlName="usersFilter">
													<ng-template ng-option-tmp let-item="item">
														{{item.name}}
													</ng-template>
												</ng-select>
											</div>
										</form>
									</div>
									<div class="search">
										<label class="search-label" for="pConfig1.search">
											<i class="fas fa-search"></i>
										</label>
										<input [placeholder]="'Shared.Type_to_filter' | translate"
											   [(ngModel)]="pConfig1.search" id="pConfig1.search" name='search'
											   class="doInput">
									</div>
									<div class="itemsPerPage">
										<label>{{'Shared.Items_per_page' | translate}}:</label>
										<div class="perPageIndicators">
											<button [ngClass]="{'active': pConfig1.itemsPerPage === 10}"
													(click)="perPageChange1(10)">10
											</button>
											<button [ngClass]="{'active': pConfig1.itemsPerPage === 20}"
													(click)="perPageChange1(20)">20
											</button>
											<button [ngClass]="{'active': pConfig1.itemsPerPage === 50}"
													(click)="perPageChange1(50)">50
											</button>
											<button [ngClass]="{'active': pConfig1.itemsPerPage === 100}"
													(click)="perPageChange1(100)">100
											</button>
										</div>
									</div>
								</div>
								<div class="table-wrapper">
									<table class="table">
										<thead>
										<tr>
											<th class="user_name" (click)="sortBy1('user_name')">
												<span>{{ 'Tax_advisors.Mobile_user_name' | translate }}</span>
												<span class="sort_icons" [hidden]="pConfig1.sortField != 'user_name'">
													<i class="fas fa-sort-down"
													   [class.sort_active]="pConfig1.sortField == 'user_name' && pConfig1.reverseOrder == true"></i>
													<i class="fas fa-sort-up"
													   [class.sort_active]="pConfig1.sortField == 'user_name' && pConfig1.reverseOrder == false"></i>
												</span>
											</th>
											<th class="action_name" (click)="sortBy1('action')">
												<span>{{'Tax_advisors.Log_action_name' | translate}}</span>
												<span class="sort_icons" [hidden]="pConfig1.sortField != 'action'">
													<i class="fas fa-sort-down"
													   [class.sort_active]="pConfig1.sortField == 'action' && pConfig1.reverseOrder == true"></i>
													<i class="fas fa-sort-up"
													   [class.sort_active]="pConfig1.sortField == 'action' && pConfig1.reverseOrder == false"></i>
												</span>
											</th>
											<th class="value_name" (click)="sortBy1('value')">
												<span>{{ 'Tax_advisors.Work_status' | translate }}</span>
												<span class="sort_icons" [hidden]="pConfig1.sortField != 'value'">
													<i class="fas fa-sort-down"
													   [class.sort_active]="pConfig1.sortField == 'value' && pConfig1.reverseOrder == true"></i>
													<i class="fas fa-sort-up"
													   [class.sort_active]="pConfig1.sortField == 'value' && pConfig1.reverseOrder == false"></i>
												</span>
											</th>
											<th class="updated_at" (click)="sortBy1('updated_at')">
												<span>{{'Tax_advisors.Log_created_at' | translate}}</span>
												<span class="sort_icons" [hidden]="pConfig1.sortField != 'updated_at'">
													<i class="fas fa-sort-down"
													   [class.sort_active]="pConfig1.sortField == 'updated_at' && pConfig1.reverseOrder == true"></i>
													<i class="fas fa-sort-up"
													   [class.sort_active]="pConfig1.sortField == 'updated_at' && pConfig1.reverseOrder == false"></i>
												</span>
											</th>
										</tr>
										</thead>
										<tbody *ngIf="managerActionLogs">
										<tr class="hover_rows" *ngFor="let i of managerActionLogs | paginate: {
											id: 'manager_action_logs_pagination',
											itemsPerPage: pConfig1.itemsPerPage,
											currentPage: pConfig1.currentPage,
											totalItems: pConfig1.totalItems
										   }">
											<td>
												{{i.user_name}}
											</td>
											<td>
												{{'Tax_advisors.' + i.action_name | translate}}
											</td>
											<td>
												{{i.value_name}}
											</td>
											<td>
												{{i.updated_at}}
											</td>
										</tr>
										</tbody>
									</table>
								</div>
								<div class="table-footer" *ngIf="managerActionLogs">
									<div class="items-total-wrapper">
										<span translate [translateParams]="{ total_items: pConfig1.totalItems }">
										  Shared.Total_items_in_table
										</span>
									</div>
									<pagination-controls id="manager_action_logs_pagination"
														 class="table-pagination"
														 (pageChange)="pageChange1($event)"
														 maxSize="5"
														 directionLinks="false"
														 autoHide="true"
														 responsive="false">
									</pagination-controls>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<ng-template #detailedTimelog>
	<div class="modal-header">
		<h5 class="modal-title pull-left">
			{{ detailedTimeLog.name_concat }}
		</h5>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="datatable-wrapper">
			<div class="filters">
				<div class="search">
					<label class="search-label" for="pConfig3.search">
						<i class="fas fa-search"></i>
					</label>
					<input [placeholder]="'Shared.Type_to_filter' | translate" [(ngModel)]="pConfig3.search"
						   id="pConfig3.search" name='search' class="doInput">
				</div>
				<div class="itemsPerPage">
					<label>{{'Shared.Items_per_page' | translate}}:</label>
					<div class="perPageIndicators">
						<button [ngClass]="{'active': pConfig3.itemsPerPage === 10}"
								(click)="pConfig3.itemsPerPage = 10; pConfig3.currentPage = 0">10
						</button>
						<button [ngClass]="{'active': pConfig3.itemsPerPage === 20}"
								(click)="pConfig3.itemsPerPage = 20; pConfig3.currentPage = 0">20
						</button>
						<button [ngClass]="{'active': pConfig3.itemsPerPage === 50}"
								(click)="pConfig3.itemsPerPage = 50; pConfig3.currentPage = 0">50
						</button>
						<button [ngClass]="{'active': pConfig3.itemsPerPage === 100}"
								(click)="pConfig3.itemsPerPage = 100; pConfig3.currentPage = 0">100
						</button>
					</div>
				</div>
			</div>
			<div class="table-wrapper">
				<table class="table">
					<thead>
					<tr>
						<th class="log_time" (click)="sortBy3('log_time')">
							<span>{{ 'Tax_advisors.Log_time' | translate }}</span>
							<span class="sort_icons" [hidden]="pConfig3.sortField != 'log_time'">
                  <i class="fas fa-sort-down"
					 [class.sort_active]="pConfig3.sortField == 'log_time' && pConfig3.reverseOrder == true"></i>
                  <i class="fas fa-sort-up"
					 [class.sort_active]="pConfig3.sortField == 'log_time' && pConfig3.reverseOrder == false"></i>
                </span>
						</th>
						<th class="log_date" (click)="sortBy3('log_date')">
							<span>{{ 'Tax_advisors.Log_date' | translate }}</span>
							<span class="sort_icons" [hidden]="pConfig3.sortField != 'log_date'">
                  <i class="fas fa-sort-down"
					 [class.sort_active]="pConfig3.sortField == 'log_date' && pConfig3.reverseOrder == true"></i>
                  <i class="fas fa-sort-up"
					 [class.sort_active]="pConfig3.sortField == 'log_date' && pConfig3.reverseOrder == false"></i>
                </span>
						</th>
						<th class="updated_at" (click)="sortBy3('updated_at')">
							<span>{{ 'Tax_advisors.Updated_at' | translate }}</span>
							<span class="sort_icons" [hidden]="pConfig3.sortField != 'updated_at'">
                  <i class="fas fa-sort-down"
					 [class.sort_active]="pConfig3.sortField == 'updated_at' && pConfig3.reverseOrder == true"></i>
                  <i class="fas fa-sort-up"
					 [class.sort_active]="pConfig3.sortField == 'updated_at' && pConfig3.reverseOrder == false"></i>
                </span>
						</th>
						<th class="details"></th>
					</tr>
					</thead>
					<tbody *ngFor="let t of detailedTimeLog.log_work | filter: pConfig3.search : 'log_time, log_date, updated_at' | orderBy: pConfig3.sortField:pConfig3.reverseOrder | paginate: {
                    id: 'detailed_logtime_pagination',
                    itemsPerPage: pConfig3.itemsPerPage,
                    currentPage: pConfig3.currentPage
                   }" class="hover_rows tbody_duplicates">
					<tr class="row_data">
						<td>{{t.log_time}}</td>
						<td>{{t.log_date || '-'}}</td>
						<td>{{t.updated_at}}</td>
						<td class="action_td show_details"
							(click)="$event.stopPropagation(); t.show_details = !t.show_details">
                <span *ngIf="!t.show_details">
                  {{ 'Tax_advisors.Show_detailed_time_log' | translate }}
                </span>
							<span *ngIf="t.show_details">
                    {{ 'Tax_advisors.Hide_detailed_time_log' | translate }}
                  </span>
						</td>
					</tr>
					<tr class="row_details" [ngClass]="{'hide': !t.show_details}">
						<td colspan="4">
							<div class="comment">
								{{t.comment}}
							</div>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<div class="table-footer">
				<div class="items-total-wrapper">
          <span translate [translateParams]="{ total_items: detailedTimeLog.log_work.length }">
            Shared.Total_items_in_table
          </span>
				</div>
				<pagination-controls id="detailed_logtime_pagination"
									 class="table-pagination"
									 (pageChange)="pageChange3($event)"
									 maxSize="5"
									 directionLinks="false"
									 autoHide="true"
									 responsive="false">
				</pagination-controls>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
			<span>{{ 'Shared.Close_modal' | translate }}</span>
		</button>
	</div>
</ng-template>
