<div class="tax-advisors-page-tpl container">
  <div class="row">
    <div class="main-content col-12">
      <div class="top-bar horizontal-tabs-wrapper-unified">
        <div class="tabs-wrapper">
          <div class="tax-advisors-tab-title tab" [class.active]="tab === 1" (click)="tabHandler(1)">
            {{ 'Tax_advisors.Tax_advisors' | translate }}
          </div>
          <div class="tax-advisors-specialists-tab-title tab" [class.active]="tab === 2" (click)="tabHandler(2)">
            {{ 'Tax_advisors.Tax_advisors_specialists' | translate }}
          </div>
        </div>
        <div class="controls-wrapper">
          <button routerLink="/taxadvisor/new" (click)="$event.stopPropagation()" class="main-action-btn no-icon-btn add-tax-advisor-btn">
            <span>{{ 'Tax_advisors.Add_advisor' | translate }}</span>
          </button>
        </div>
      </div>

      <div class="tab-content">

        <div *ngIf="tab === 1" id="tax-advisors-list" class="tax-advisors-list tab-content">
          <div class="datatable-wrapper">
            <div class="filters" >
              <div class="search">
                <label class="search-label" for="pConfig1.search">
                  <i class="fas fa-search"></i>
                </label>
                <input [placeholder]="'Shared.Type_to_filter' | translate" [(ngModel)]="pConfig1.search" id="pConfig1.search" name='search' class="doInput">
              </div>
              <div class="itemsPerPage">
                <label>{{'Shared.Items_per_page' | translate}}:</label>
                <div class="perPageIndicators">
                  <button [ngClass]="{'active': pConfig1.itemsPerPage === 10}" (click)="pConfig1.itemsPerPage = 10; pConfig1.currentPage = 0">10</button>
                  <button [ngClass]="{'active': pConfig1.itemsPerPage === 20}" (click)="pConfig1.itemsPerPage = 20; pConfig1.currentPage = 0">20</button>
                  <button [ngClass]="{'active': pConfig1.itemsPerPage === 50}" (click)="pConfig1.itemsPerPage = 50; pConfig1.currentPage = 0">50</button>
                  <button [ngClass]="{'active': pConfig1.itemsPerPage === 100}" (click)="pConfig1.itemsPerPage = 100; pConfig1.currentPage = 0">100</button>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                <tr>
                  <th class="name" (click)="sortBy1('name')">
                    <span>{{'Tax_advisors.Name' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig1.sortField != 'name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'name' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'name' && pConfig1.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="contact_person" (click)="sortBy1('contact_person')">
                    <span>{{'Tax_advisors.Contact_person' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig1.sortField != 'contact_person'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'contact_person' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'contact_person' && pConfig1.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="email" (click)="sortBy1('email')">
                    <span>{{'Tax_advisors.Email' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig1.sortField != 'email'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'email' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'email' && pConfig1.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="phone_number" (click)="sortBy1('phone_number')">
                    <span>{{'Tax_advisors.Phone' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig1.sortField != 'phone_number'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'phone_number' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'phone_number' && pConfig1.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="company_details">
                    <span>{{'Tax_advisors.Company_details' | translate}}</span>
                  </th>
                  <th class="edit"></th>
                  <th class="delete"></th>
                </tr>
                </thead>
                <tbody *ngIf="taxAdvisors">
                <tr (click)="goToSingleAdvisorsPage(i)" class="hover_rows" *ngFor="let i of taxAdvisors | filter: pConfig1.search :'name,contact_person,email,phone_number,description' | orderBy: pConfig1.sortField:pConfig1.reverseOrder | paginate: {
                    id: 'tax_advisor_pagination',
                    itemsPerPage: pConfig1.itemsPerPage,
                    currentPage: pConfig1.currentPage
                   }">
                  <td>
                    {{i.name}}
                  </td>
                  <td>
                    {{i.contact_person}}
                  </td>
                  <td>
                    {{i.email}}
                  </td>
                  <td>
                    {{i.phone_number}}
                  </td>
                  <td class="pre-line-data">
                    {{i.description}}
                  </td>
                  <td class="action_td" (click)="$event.stopPropagation(); editTaxAdvisor(i)">
                    <i class="fas fa-pencil-alt"></i>
                  </td>
                  <td class="action_td" (click)="$event.stopPropagation(); deleteTaxAdvisor(i)">
                    <i class="fas fa-trash"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="table-footer" *ngIf="taxAdvisors">
              <div class="items-total-wrapper">
                <span translate [translateParams]="{ total_items: taxAdvisors.length }">
                  Shared.Total_items_in_table
                </span>
              </div>
              <pagination-controls  id="tax_advisor_pagination"
                                    class="table-pagination"
                                    (pageChange)="pageChange1($event)"
                                    maxSize="5"
                                    directionLinks="false"
                                    autoHide="true"
                                    responsive="false">
              </pagination-controls>
            </div>
          </div>
        </div>

        <div *ngIf="tab === 2" id="tax-advisors-managers-list" class="tax-advisors-managers-list tab-content">
          <div class="datatable-wrapper">
            <div class="filters" >
              <div class="search">
                <label class="search-label" for="pConfig2.search">
                  <i class="fas fa-search"></i>
                </label>
                <input [placeholder]="'Shared.Type_to_filter' | translate" [(ngModel)]="pConfig2.search" id="pConfig2.search" name='search' class="doInput">
              </div>
              <div class="itemsPerPage">
                <label>{{'Shared.Items_per_page' | translate}}:</label>
                <div class="perPageIndicators">
                  <button [ngClass]="{'active': pConfig2.itemsPerPage === 10}" (click)="pConfig2.itemsPerPage = 10; pConfig2.currentPage = 0">10</button>
                  <button [ngClass]="{'active': pConfig2.itemsPerPage === 20}" (click)="pConfig2.itemsPerPage = 20; pConfig2.currentPage = 0">20</button>
                  <button [ngClass]="{'active': pConfig2.itemsPerPage === 50}" (click)="pConfig2.itemsPerPage = 50; pConfig2.currentPage = 0">50</button>
                  <button [ngClass]="{'active': pConfig2.itemsPerPage === 100}" (click)="pConfig2.itemsPerPage = 100; pConfig2.currentPage = 0">100</button>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                <tr>
                  <th class="name" (click)="sortBy2('name')">
                    <span>{{'Tax_advisors.User_name' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'name' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'name' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="email" (click)="sortBy2('email')">
                    <span>{{'Tax_advisors.User_email' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'email'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'email' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'email' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="phone_number" (click)="sortBy2('phone_number')">
                    <span>{{'Tax_advisors.User_phone' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'phone_number'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'phone_number' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'phone_number' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="manager_status" (click)="sortBy2('is_active')">
                    <span>{{'Tax_advisors.User_status' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'is_active'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'is_active' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'is_active' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="manager_tax_advisor" (click)="sortBy2('tax_advisers_name')">
                    <span>{{'Tax_advisors.User_tax_adviser' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'tax_advisers_name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'tax_advisers_name' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'tax_advisers_name' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="edit"></th>
                  <th class="delete"></th>
                </tr>
                </thead>
                <tbody *ngIf="taxAdvisorsManagers" >
                <tr (click)="goToSingleTaxAdvisorsManagerPage(i)" class="hover_rows" *ngFor="let i of taxAdvisorsManagers | filter: pConfig2.search: 'name,email,phone_number,tax_advisers_name' | orderBy: pConfig2.sortField:pConfig2.reverseOrder | paginate: {
                    id: 'tax_advisor_managers_pagination',
                    itemsPerPage: pConfig2.itemsPerPage,
                    currentPage: pConfig2.currentPage
                   }">
                  <td>
                    {{i.name}}
                  </td>
                  <td>
                    {{i.email}}
                  </td>
                  <td>
                    {{i.phone_number}}
                  </td>
                  <td class="status_td">
                    <i [class.active]="i.is_active === 1" class="fas fa-circle"></i>
                  </td>
                  <td>
                    {{i.tax_advisers_name}}
                  </td>
                  <td class="action_td" (click)="$event.stopPropagation(); editTaxAdvisorsManager(i)">
                    <i class="fas fa-pencil-alt"></i>
                  </td>
                  <td [class.activate]="!i.is_active" class="action_td" (click)="$event.stopPropagation(); deleteTaxAdvisorManager(i)">
                    <i class="fas fa-power-off"></i>
                    <!--<i class="fas fa-trash"></i>-->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="taxAdvisorsManagers" class="table-footer">
              <div class="items-total-wrapper">
                <span translate [translateParams]="{ total_items: taxAdvisorsManagers.length }">
                  Shared.Total_items_in_table
                </span>
              </div>
              <pagination-controls  id="tax_advisor_managers_pagination"
                                    class="table-pagination"
                                    (pageChange)="pageChange2($event)"
                                    maxSize="5"
                                    directionLinks="false"
                                    autoHide="true"
                                    responsive="false">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>

      <!--<tabset class="tabs-wrapper" type="pills">

        <tab id="tab1" customClass="tab-pills">
          <ng-template tabHeading>
            <span class="tax-advisors-tab-title">{{ 'Tax_advisors.Tax_advisors' | translate }}</span>
            <button routerLink="/taxadvisor/new" (click)="$event.stopPropagation()" class="main-action-btn no-icon-btn add-tax-advisor-btn">
              <span>{{ 'Tax_advisors.Add_advisor' | translate }}</span>
            </button>
          </ng-template>
          <div class="tax-advisors-table-wrapper datatable-wrapper">
            <div class="filters" >
              <div class="search">
                <label class="search-label" for="pConfig1.search">
                  <i class="fas fa-search"></i>
                </label>
                <input [placeholder]="'Shared.Type_to_filter' | translate" [(ngModel)]="pConfig1.search" id="pConfig1.search" name='search' class="doInput">
              </div>
              <div class="itemsPerPage">
                <label>{{'Shared.Items_per_page' | translate}}:</label>
                <div class="perPageIndicators">
                  <button [ngClass]="{'active': pConfig1.itemsPerPage === 1}" (click)="pConfig1.itemsPerPage = 1; pConfig1.currentPage = 0">1</button>
                  <button [ngClass]="{'active': pConfig1.itemsPerPage === 5}" (click)="pConfig1.itemsPerPage = 5; pConfig1.currentPage = 0">5</button>
                  <button [ngClass]="{'active': pConfig1.itemsPerPage === 10}" (click)="pConfig1.itemsPerPage = 10; pConfig1.currentPage = 0">10</button>
                  <button [ngClass]="{'active': pConfig1.itemsPerPage === 20}" (click)="pConfig1.itemsPerPage = 20; pConfig1.currentPage = 0">20</button>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                  <tr>
                  <th class="name" (click)="sortBy1('name')">
                    <span>{{'Tax_advisors.Name' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig1.sortField != 'name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'name' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'name' && pConfig1.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="contact_person" (click)="sortBy1('contact_person')">
                    <span>{{'Tax_advisors.Contact_person' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig1.sortField != 'contact_person'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'contact_person' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'contact_person' && pConfig1.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="email" (click)="sortBy1('email')">
                    <span>{{'Tax_advisors.Email' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig1.sortField != 'email'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'email' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'email' && pConfig1.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="phone_number" (click)="sortBy1('phone_number')">
                    <span>{{'Tax_advisors.Phone' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig1.sortField != 'phone_number'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'phone_number' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'phone_number' && pConfig1.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="company_details">
                    <span>{{'Tax_advisors.Company_details' | translate}}</span>
                  </th>
                  <th class="edit"></th>
                  <th class="delete"></th>
                </tr>
                </thead>
                <tbody *ngIf="taxAdvisors">
                <tr (click)="goToSingleAdvisorsPage(i)" class="hover_rows" *ngFor="let i of taxAdvisors | filter: pConfig1.search :'name,contact_person,email,phone_number,description' | orderBy: pConfig1.sortField:pConfig1.reverseOrder | paginate: {
                    id: 'tax_advisor_pagination',
                    itemsPerPage: pConfig1.itemsPerPage,
                    currentPage: pConfig1.currentPage
                   }">
                  <td>
                    {{i.name}}
                  </td>
                  <td>
                    {{i.contact_person}}
                  </td>
                  <td>
                    {{i.email}}
                  </td>
                  <td>
                    {{i.phone_number}}
                  </td>
                  <td class="pre-line-data">
                    {{i.description}}
                  </td>
                  <td class="action_td" (click)="$event.stopPropagation(); editTaxAdvisor(i)">
                    <i class="fas fa-pencil-alt"></i>
                  </td>
                  <td class="action_td" (click)="$event.stopPropagation(); deleteTaxAdvisor(i)">
                    <i class="fas fa-trash"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="table-footer" *ngIf="taxAdvisors">
              <div class="items-total-wrapper">
                <span translate [translateParams]="{ total_items: taxAdvisors.length }">
                  Shared.Total_items_in_table
                </span>
              </div>
              <pagination-controls  id="tax_advisor_pagination"
                                    class="table-pagination"
                                    (pageChange)="pageChange1($event)"
                                    maxSize="5"
                                    directionLinks="false"
                                    autoHide="true"
                                    responsive="false">
              </pagination-controls>
            </div>
          </div>
        </tab>
        <tab id="tab2" customClass="tab-pills">
          <ng-template tabHeading>
            <span class="tax-advisors-specialists-tab-title">{{ 'Tax_advisors.Tax_advisors_specialists' | translate }}</span>
          </ng-template>
          <div class="tax-advisors-specialists-table-wrapper datatable-wrapper">
            <div class="filters" >
              <div class="search">
                <label class="search-label" for="pConfig2.search">
                  <i class="fas fa-search"></i>
                </label>
                <input [placeholder]="'Shared.Type_to_filter' | translate" [(ngModel)]="pConfig2.search" id="pConfig2.search" name='search' class="doInput">
              </div>
              <div class="itemsPerPage">
                <label>{{'Shared.Items_per_page' | translate}}:</label>
                <div class="perPageIndicators">
                  <button [ngClass]="{'active': pConfig2.itemsPerPage === 1}" (click)="pConfig2.itemsPerPage = 1; pConfig2.currentPage = 0">1</button>
                  <button [ngClass]="{'active': pConfig2.itemsPerPage === 5}" (click)="pConfig2.itemsPerPage = 5; pConfig2.currentPage = 0">5</button>
                  <button [ngClass]="{'active': pConfig2.itemsPerPage === 10}" (click)="pConfig2.itemsPerPage = 10; pConfig2.currentPage = 0">10</button>
                  <button [ngClass]="{'active': pConfig2.itemsPerPage === 20}" (click)="pConfig2.itemsPerPage = 20; pConfig2.currentPage = 0">20</button>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                <tr>
                  <th class="name" (click)="sortBy2('name')">
                    <span>{{'Tax_advisors.User_name' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'name' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'name' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="email" (click)="sortBy2('email')">
                    <span>{{'Tax_advisors.User_email' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'email'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'email' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'email' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="phone_number" (click)="sortBy2('phone_number')">
                    <span>{{'Tax_advisors.User_phone' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'phone_number'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'phone_number' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'phone_number' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="manager_status" (click)="sortBy2('is_active')">
                    <span>{{'Tax_advisors.User_status' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'is_active'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'is_active' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'is_active' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="manager_tax_advisor" (click)="sortBy2('tax_advisers_name')">
                    <span>{{'Tax_advisors.User_tax_adviser' | translate}}</span>
                    <span class="sort_icons" [hidden]="pConfig2.sortField != 'tax_advisers_name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig2.sortField == 'tax_advisers_name' && pConfig2.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig2.sortField == 'tax_advisers_name' && pConfig2.reverseOrder == false"></i>
                    </span>
                  </th>
                  <th class="edit"></th>
                  <th class="delete"></th>
                </tr>
                </thead>
                <tbody *ngIf="taxAdvisorsManagers" >
                <tr (click)="goToSingleTaxAdvisorsManagerPage(i)" class="hover_rows" *ngFor="let i of taxAdvisorsManagers | filter: pConfig2.search: 'name,email,phone_number,tax_advisers_name' | orderBy: pConfig2.sortField:pConfig2.reverseOrder | paginate: {
                    id: 'tax_advisor_managers_pagination',
                    itemsPerPage: pConfig2.itemsPerPage,
                    currentPage: pConfig2.currentPage
                   }">
                  <td>
                    {{i.name}}
                  </td>
                  <td>
                    {{i.email}}
                  </td>
                  <td>
                    {{i.phone_number}}
                  </td>
                  <td class="status_td">
                    <i [class.active]="i.is_active === 1" class="fas fa-circle"></i>
                  </td>
                  <td>
                    {{i.tax_advisers_name}}
                  </td>
                  <td class="action_td" (click)="$event.stopPropagation(); editTaxAdvisorsManager(i)">
                    <i class="fas fa-pencil-alt"></i>
                  </td>
                  <td [class.activate]="!i.is_active" class="action_td" (click)="$event.stopPropagation(); deleteTaxAdvisorManager(i)">
                    <i class="fas fa-power-off"></i>
                    &lt;!&ndash;<i class="fas fa-trash"></i>&ndash;&gt;
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="taxAdvisorsManagers" class="table-footer">
              <div class="items-total-wrapper">
                <span translate [translateParams]="{ total_items: taxAdvisorsManagers.length }">
                  Shared.Total_items_in_table
                </span>
              </div>
              <pagination-controls  id="tax_advisor_managers_pagination"
                                    class="table-pagination"
                                    (pageChange)="pageChange2($event)"
                                    maxSize="5"
                                    directionLinks="false"
                                    autoHide="true"
                                    responsive="false">
              </pagination-controls>
            </div>
          </div>
        </tab>
      </tabset>-->
    </div>
  </div>
</div>

