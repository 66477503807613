<div class="container edit-user-attribute-tpl">
  <div class="row">

    <div class="top-bar col-12">
      <div class="breadcrumbs">
        <span class="breadcrumb-item">
          <a [routerLink]="['/settings/user-attributes']">
            {{ 'Settings.User_attributes.Page_title' | translate }}
          </a>
        </span>
        <span class="breadcrumb-item">
          {{ 'Settings.User_attributes.Edit_attr' | translate }}
        </span>
      </div>
      <div class="controls">
        <button class="submit-form-btn no-icon-btn" form="editAttributeForm">
          <span>{{ 'Shared.Save' | translate }}</span>
        </button>
        <button class="cancel-form-btn no-icon-btn" [routerLink]="['/settings/user-attributes']" type="button">
          <span>{{ 'Shared.Cancel' | translate }}</span>
        </button>
      </div>
    </div>

    <div class="col-12 main-content">

      <form id="editAttributeForm" name="editAttributeForm" class="editAttributeForm" (ngSubmit)="f.form.valid && editAttribute(userAttributeModel, titleTranslations)" #f="ngForm" novalidate>

        <div class="translations-wrapper col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">

          <div class="form-group required_section" [ngClass]="{ 'has-error': f.submitted && !title_EN.valid }">

            <div class="input-wrapper title">
              <label for="title">{{'Settings.User_attributes.Attribute_name' | translate}} (EN) <span class="required_asterisk">*</span></label>
              <input class="form-control" name="title" id="title" [(ngModel)]="userAttributeModel.enTitle" #title_EN="ngModel" required/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !title_EN.valid">
              <p *ngIf="f.submitted && !title_EN.valid">{{'Shared.Field_is_required' | translate}}</p>
            </div>
          </div>
          <div class="form-group" *ngFor="let q of titleTranslations; let i = index">
            <div *ngFor="let key of objectKeys(q)">
              <div class="input-wrapper title">
                <label for="{{key}}_title">{{'Settings.User_attributes.Attribute_name' | translate}} ({{key}})</label>
                <input class="form-control" name="{{key}}_title" id="{{key}}_title" [(ngModel)]="q[key].title" #title="ngModel"/>
              </div>
            </div>
          </div>

        </div>

        <div class="additional-attr-info-wrapper col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="form-group">
            <div class="input-wrapper type">
              <label for="selectedType">{{'Settings.User_attributes.User_attribute_type_name' | translate}}</label>
              <ng-select [items]="attributeTypes"
                         bindLabel="name"
                         bindValue="id"
                         appendTo=".input-wrapper.type"
                         name="selectedType"
                         labelForId="selectedType"
                         id="selectedType"
                         class="selectedType"
                         [multiple]="false"
                         [clearable]="false"
                         [notFoundText]="'Settings.User_attributes.No_documents_found' | translate"
                         [placeholder]="'Settings.User_attributes.Select_document_types_placeholder' | translate"
                         [closeOnSelect]="true"
                         [hideSelected]="false"
                         [searchable]="false"
                         [selectableGroup]="false"
                         [selectableGroupAsModel]="false"
                         [class.long-string]="userAttributeModel.selectedType === 3"
                         [(ngModel)]="userAttributeModel.selectedType">
                <ng-template ng-label-tmp let-item="item">
                  {{'Settings.User_attributes.' + item.name | translate}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  {{'Settings.User_attributes.' + item.name | translate}}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <div class="input-wrapper attrs">
              <label for="document_types">{{ 'Settings.User_attributes.Select_document_types' | translate}}</label>
              <ng-select [items]="documentTypesList"
                         bindLabel="title_to_show"
                         bindValue="id"
                         #selectD
                         groupBy="category_title_to_show"
                         appendTo=".input-wrapper.attrs"
                         name="document_types"
                         labelForId="document_types"
                         id="document_types"
                         [multiple]="true"
                         [notFoundText]="'Settings.User_attributes.No_documents_found' | translate"
                         [placeholder]="'Settings.User_attributes.Select_document_types_placeholder' | translate"
                         [closeOnSelect]="false"
                         [hideSelected]="false"
                         [searchable]="true"
                         [selectableGroup]="true"
                         [selectableGroupAsModel]="false"
                         [(ngModel)]="userAttributeModel.selectedDocumentTypes">
                <ng-template ng-optgroup-tmp let-item="item">
                  <span>
                    {{item.category_title_to_show}}
                    <span class="select_all_group_icon">
                      {{'Settings.User_attributes.Select_whole_group_of_documents' | translate}}
                    </span>
                    <span class="deselect_all_group_icon">
                      {{'Settings.User_attributes.Deselect_whole_group_of_documents' | translate}}
                    </span>
                  </span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  {{item.title_to_show}}
                </ng-template>
                <ng-template ng-footer-tmp>
                  <button class="main-action-btn no-icon-btn" (click)="closeSelect(selectD)">
                    {{'Settings.User_attributes.Confirm_selected_documents_btn' | translate}}
                  </button>
                </ng-template>
              </ng-select>
              <div class="ng-select-controls" [class.hide_clear_all]="!userAttributeModel.selectedDocumentTypes.length">
                <button class="cancel-form-btn no-icon-btn clear_all_documents_btn" type="button" (click)="clearAllNew(true)">
                  {{'Settings.User_attributes.Clear_all_documents_btn' | translate}}
                </button>
                <button class="main-action-btn no-icon-btn add_more_documents_btn" type="button" (click)="openSelect(selectD)">
                  {{'Settings.User_attributes.Add_more_documents_btn' | translate}}
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-wrapper attrs">
              <label for="questionnaires">{{ 'Settings.User_attributes.Select_questionnaires' | translate}}</label>
              <ng-select [items]="questionnairesList"
                         bindLabel="question_to_show"
                         bindValue="id"
                         #selectQ
                         groupBy="category_title_to_show"
                         appendTo=".input-wrapper.attrs"
                         name="questionnaires"
                         labelForId="questionnaires"
                         id="questionnaires"
                         [multiple]="true"
                         [notFoundText]="'Settings.User_attributes.No_questionnaires_found' | translate"
                         [placeholder]="'Settings.User_attributes.Select_questionnaires_placeholder' | translate"
                         [closeOnSelect]="false"
                         [hideSelected]="false"
                         [searchable]="true"
                         [selectableGroup]="true"
                         [selectableGroupAsModel]="false"
                         [(ngModel)]="userAttributeModel.selectedQuestionnaires">
                <ng-template ng-optgroup-tmp let-item="item">
                  <span>
                    {{item.category_title_to_show}}
                    <span class="select_all_group_icon">
                      {{'Settings.User_attributes.Select_whole_group_of_questionnaires' | translate}}
                    </span>
                    <span class="deselect_all_group_icon">
                      {{'Settings.User_attributes.Deselect_whole_group_of_questionnaires' | translate}}
                    </span>
                  </span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  {{item.question_to_show}}
                </ng-template>
                <ng-template ng-footer-tmp>
                  <button class="main-action-btn no-icon-btn" (click)="closeSelect(selectQ)">
                    {{'Settings.User_attributes.Confirm_selected_questionnaires_btn' | translate}}
                  </button>
                </ng-template>
              </ng-select>
              <div class="ng-select-controls" [class.hide_clear_all]="!userAttributeModel.selectedQuestionnaires.length">
                <button class="cancel-form-btn no-icon-btn clear_all_documents_btn" type="button" (click)="clearAllNew(false)">
                  {{'Settings.User_attributes.Clear_all_questionnaires_btn' | translate}}
                </button>
                <button class="main-action-btn no-icon-btn add_more_documents_btn" type="button" (click)="openSelect(selectQ)">
                  {{'Settings.User_attributes.Add_more_questionnaires_btn' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
