import {NgModule} from "@angular/core";
import {RouterModule, Routes, PreloadAllModules} from "@angular/router";
import {AuthGuard} from "./_guards/index";

import {CategoriesListComponent} from "./categories/categories.component";
import {CreateCategoryComponent} from "./categories/create/create-category.component";
import {CreateSubcategoryComponent} from "./categories/create/create-subcategory.component";
import {EditCategoryComponent} from "./categories/edit/edit-category.component";
import {EditSubcategoryComponent} from "./categories/edit/edit-subcategory.component";
import {CreateQuestionnaireComponent} from "./categories/questionnaire/create/create-questionnaire.component";
import {EditQuestionnaireComponent} from "./categories/questionnaire/edit/edit-questionnaire.component";
import {LoginComponent} from "./login/login.component";
import {TaxAdvisorsComponent} from "./taxadvisors/taxadvisors.component";
import {SingleTaxAdvisorComponent} from "./taxadvisors/single-taxadvisor/single-taxadvisor.component";
import {SingleTaxadvisorsManagerComponent} from "./taxadvisors/single-taxadvisors-manager/single-taxadvisors-manager.component";
import {CreateTaxAdvisorComponent} from "./taxadvisors/create/create-taxadvisor.component";
import {CreateTaxAdvisorsManagerComponent} from "./taxadvisors/create/create-taxadvisors-manager.component";
import {EditTaxAdvisorComponent} from "./taxadvisors/edit/edit-taxadvisor.component";
import {EditTaxAdvisorsManagerComponent} from "./taxadvisors/edit/edit-taxadvisors-manager.component";
import {SettingsComponent} from "./settings/settings.component";
import {LanguageComponent} from "./settings/language/language.component";
import {UserAttributesComponent} from "./settings/user-attributes/user-attributes.component";
import {FaqComponent} from "./settings/faq/faq.component";
import {DecisionTreeComponent} from "./decision-tree/decision-tree.component";
import {DecisionTreeVisualizationComponent} from "./decision-tree/decision-tree-visualization/decision-tree-visualization.component";
import {DecisionTreeTableComponent} from "./decision-tree/decision-tree-table/decision-tree-table.component";
import {CreateUserAttributeComponent} from "./settings/user-attributes/create-user-attribute/create-user-attribute.component";
import {EditUserAttributeComponent} from "./settings/user-attributes/edit-user-attribute/edit-user-attribute.component";
import {EmailSetupComponent} from "./settings/email-setup/email-setup.component"

const routes: Routes = [
	{
		path: "",
		redirectTo: "mobile-users/list/kanban",
		canActivate: [AuthGuard],
		data: {role: "operator"},
		pathMatch: "full"
	},
	{path: "categories", component: CategoriesListComponent, canActivate: [AuthGuard], data: {role: "operator"}},
	{path: "login", component: LoginComponent},
	{path: "category/new", component: CreateCategoryComponent, canActivate: [AuthGuard], data: {role: "operator"}},
	{path: "category/:id/edit", component: EditCategoryComponent, canActivate: [AuthGuard], data: {role: "operator"}},
	{
		path: "category/:id/subcategory/new",
		component: CreateSubcategoryComponent,
		canActivate: [AuthGuard],
		data: {role: "operator"}
	},
	{
		path: "category/:id/subcategory/:sub_id/edit",
		component: EditSubcategoryComponent,
		canActivate: [AuthGuard],
		data: {role: "operator"}
	},
	{
		path: "category/:id/questionnaire/new",
		component: CreateQuestionnaireComponent,
		canActivate: [AuthGuard],
		data: {role: "operator"}
	},
	{
		path: "category/:id/questionnaire/:que_id/edit",
		component: EditQuestionnaireComponent,
		canActivate: [AuthGuard],
		data: {role: "operator"}
	},
	{path: "taxadvisors", component: TaxAdvisorsComponent, canActivate: [AuthGuard], data: {role: "operator"}},
	{
		path: "taxadvisors/:id",
		component: SingleTaxAdvisorComponent,
		canActivate: [AuthGuard],
		pathMatch: "full",
		data: {role: "operator"}
	},
	{
		path: "taxadvisor/new",
		component: CreateTaxAdvisorComponent,
		canActivate: [AuthGuard],
		pathMatch: "full",
		data: {role: "operator"}
	},
	{
		path: "taxadvisors/:id/edit",
		component: EditTaxAdvisorComponent,
		canActivate: [AuthGuard],
		pathMatch: "full",
		data: {role: "operator"}
	},
	{
		path: "taxadvisors/:id/manager/new",
		component: CreateTaxAdvisorsManagerComponent,
		canActivate: [AuthGuard],
		pathMatch: "full",
		data: {role: "operator"}
	},
	{
		path: "taxadvisors/:id/manager/:manager_id",
		component: SingleTaxadvisorsManagerComponent,
		canActivate: [AuthGuard],
		pathMatch: "full",
		data: {role: "operator"}
	},
	{
		path: "taxadvisors/:id/manager/:manager_id/edit",
		component: EditTaxAdvisorsManagerComponent,
		canActivate: [AuthGuard],
		pathMatch: "full",
		data: {role: "operator"}
	},
	{
		path: "settings",
		component: SettingsComponent,
		canActivate: [AuthGuard],
		data: {role: "operator"},
		children: [
			{path: "", redirectTo: "language", pathMatch: "full", data: {role: "operator"}},
			{path: "language", component: LanguageComponent, data: {role: "operator"}},
			{path: "user-attributes", component: UserAttributesComponent, data: {role: "operator"}},
			{path: "user-attributes/new", component: CreateUserAttributeComponent, data: {role: "operator"}},
			{path: "user-attributes/:id", component: EditUserAttributeComponent, data: {role: "operator"}},
			{path: "faq", component: FaqComponent, data: {role: "operator"}},
			{path: "email-setup", component: EmailSetupComponent, data: {role: "operator"}}
		]
	},
	{
		path: "decision-tree",
		component: DecisionTreeComponent,
		canActivate: [AuthGuard],
		data: {role: "operator"},
		children: [
			{path: "tree-view", component: DecisionTreeVisualizationComponent, data: {role: "operator"}},
			{path: "table-view", component: DecisionTreeTableComponent, data: {role: "operator"}}
		]
	},
	{
		path: "mobile-users",
		loadChildren: "./mobile-users/mobile-users.module#MobileUsersModule"
	},
	
	{path: "**", component: LoginComponent}
];

@NgModule({
	exports: [RouterModule],
	imports: [RouterModule.forRoot(
		routes,
		{
			preloadingStrategy: PreloadAllModules,
			initialNavigation: "enabled"
		})],
	declarations: []
})
export class AppRoutingModule {
}
