import { Component, OnInit } from '@angular/core';
import { CategoryService, AlertService } from "../../../_services/index";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-edit-questionnaire',
  templateUrl: './edit-questionnaire.component.html',
  styleUrls: ['./edit-questionnaire.component.less']
})
export class EditQuestionnaireComponent implements OnInit {
  
  objectKeys = Object.keys;
  questionnaireId: any = this.route.snapshot.params['que_id'];
  questionnaireModel: any = {};
  translations: any[] = [];
  t: any[] = [];
  
  constructor(
    private _categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private _alertService: AlertService
  ) { }

  ngOnInit() {
    this.getSingleSubcategoryById();
  }
  
  onDataChange(type, identifier, data): void {
    let pushFunc = (array) => {
      array.push({
        'language_code': identifier,
        'question': data
      });
    };
  
    this.t.forEach((item, index) => {
      if (item.language_code === identifier) {
        this.t.splice(index, 1);
      }
    });
    pushFunc(this.t);
  }
  
  getSingleSubcategoryById(): void {
    this._categoryService.getSingleQuestionnaire({
        form_id: this.questionnaireId
      }).subscribe(res => {
        let temp = res.result[0];
        for (const w of Object.keys(temp.question_translation)) {
          let i = {
            [w]: {
              'question': temp.question_translation[w]
            }
          };
          this.translations.push(i);
        }
        this.translations.reverse();
        this.questionnaireModel = temp;
      }, err => {
        console.log(err);
      });
  }
  
  editQuestionnaire(data): void {
    let objToSend = {
      attribute_id: data.attribute_id,
      form_id: data.id,
      category_id: data.category_id,
      questions: this.t
    };
  
    console.log(objToSend);
    
    this._categoryService.editQuestionnaire(objToSend)
      .subscribe(res => {
        this._alertService.success('Alert.Changes saved successfully');
        this.router.navigate(['/categories']);
      }, err => {
        console.log(err);
      });
  }

}
