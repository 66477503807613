import {Injectable, EventEmitter} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class SharedService {
	
	public loggedOutSubject = new Subject<any>();
	public loggedOutObs$ = this.loggedOutSubject.asObservable();
	loggedOut() {
		this.loggedOutSubject.next();
	}
	
	
	public printTaxidEmitter: EventEmitter<any> = new EventEmitter();
	public saveTaxidChangesEmitter: EventEmitter<any> = new EventEmitter();
	
	changeLanguageEvent: EventEmitter<any> = new EventEmitter();
	
	private mobileUserData = new Subject<any>();
	private childLoad = new Subject<any>();
	private printTaxidSubject = new Subject<any>();
	passMobileUserData$ = this.mobileUserData.asObservable();
	childLoadEmit$ = this.childLoad.asObservable();
	printTaxidTrigger$ = this.printTaxidSubject.asObservable();
	
	constructor() {
	}
	
	passData(data) {
		this.mobileUserData.next(data);
	}
	
	emitChanges() {
		this.childLoad.next();
	}
	
	printTaxid() {
		this.printTaxidSubject.next();
	}
	
	
	
}
