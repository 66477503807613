import {ApplicationRef, ChangeDetectorRef, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Router} from "@angular/router";

// import { environment } from "../../environments/environment";
import {EnvService} from "./env.service";
import {AlertService} from "./alert.service";
import {SharedService} from "./shared.service";

@Injectable()
export class AuthenticationService {
	
	// private API_BASE = environment.apiBase;
	private USER;
	
	constructor(
		private env: EnvService,
		private http: HttpClient,
		private router: Router,
		private _AlertService: AlertService,
		private _SharedService: SharedService
	) {
	}
	
	login(email: string, password: string) {
		return this.http.post<any>(`${this.env.apiBase}/signin`, {
			email: email,
			password: password
		}).subscribe(data => {
			if (data["user"] && data["user"].api_key) {
				// login successful if there's a jwt token in the response
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				this.USER = data["user"];
				localStorage.setItem("currentUser", JSON.stringify(data["user"]));
				if (data["user"].role === "manager") {
					this.router.navigate(["mobile-users/"]);
					this._AlertService.success("Alert.Successful_login_as_manager");
				} else if (data["user"].role === "operator") {
					this.router.navigate(["/"]);
					this._AlertService.success("Alert.Successful_login_as_operator");
				}
			}
		}, err => {
			if (err.status === 401) {
				this._AlertService.error("Alert.Wrong_email_or_password");
			} else {
				this._AlertService.error(err.status);
			}
		});
	}
	
	logout(): void {
		// remove user from local storage to log user out
		for (let i = 0; i < localStorage.length; i++) {
			let key = localStorage.key(i);
			// let value = localStorage.getItem(key);
			if (key !== "wrappedNodes" && key !== "lang") {
				localStorage.removeItem(key);
			}
		}
		// localStorage.clear();
		this.router.navigate(["/login"]);
		this._SharedService.loggedOut()
	}
	
}
