<div class="container-fluid">
	<div class="row">
		<div class="top-bar col-12">
			<div class="controls">
				<button [disabled]="!treeWasChanged" type="button" class="submit-form-btn no-icon-btn save-tree-changes"
						(click)="importDecisionTree()">
					<span>{{ 'Shared.Save_changes' | translate }}</span>
				</button>
				<button [disabled]="!treeWasChanged" class="cancel-form-btn no-icon-btn"
						(click)="getTree('cancel', wrappedNodes)" type="button">
					<span>{{ 'Shared.Cancel_changes' | translate }}</span>
				</button>
			</div>
		</div>
	</div>
</div>

<drag-scroll class="tree-wrapper">
	<div class="scale-controls">
		<div class="scale">{{scale}}</div>
		<div class="reset-zoom" (click)="resetZoom()"><i class="fas fa-home"></i></div>
		<div class="zoom-in" (click)="zoomIn()"><i class="fas fa-search-plus"></i></div>
		<div class="zoom-out" (click)="zoomOut()"><i class="fas fa-search-minus"></i></div>
		<div class="wrap-all" (click)="wrapAll()">Wrap all</div>
	</div>
	<div class="visualized-decision-tree" appTreeZoom (scaleChange)="displayScale($event)">
		<app-node-wrap class="app-node-wrap" (importTreeEvent)="importDecisionTree()"
					   (changesWereMade)="receiveMessageAboutChanges()" (deleteNodeEvent)="receiveMessage($event)"
					   (nodeCollapseEvent)="receiveMessageCollapse($event)"
					   (copyBranch)="receiveMessageDuplicate($event)" [QAList]="QAList"
					   [attributes]="availableAttributes"></app-node-wrap>
	</div>
</drag-scroll>
