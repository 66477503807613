import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
// import { environment } from "../../environments/environment";
import { AlertService } from "./alert.service";
import { EnvService } from "./env.service";

@Injectable()
export class TaxAdvisorsService {
  
  // private API_BASE = environment.apiBase;
  USER: any;
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }

  constructor(
    private env: EnvService,
    private http: HttpClient,
    private _alertService: AlertService
  ) {
    this.getUserData();
  }
  
  public getTaxAdvisorsList(): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public getSingleTaxAdvisorData(data): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('tax_adviser_id', data.id);
    return this.http.get<Object>(url, {params});
  }
  
  public createTaxAdvisor(data): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post<Object>(url, {
      'name': data.name,
      'company_name': data.legal_name,
      'description': data.company_details,
      'contact_person': data.contact_person,
      'email': data.email,
      'phone_number': data.phone_number,
      'is_active': 1
    }, {params});
  }
  
  public editTaxAdvisor(id, data): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('tax_adviser_id', id);
    return this.http.post<Object>(url, {
      'name': data.name,
      'company_name': data.legal_name,
      'description': data.company_details,
      'contact_person': data.contact_person,
      'email': data.email,
      'phone_number': data.phone_number,
      'is_active': 1
    }, {params});
  }
  
  public deleteTaxAdvisor(data): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('tax_adviser_id', data.id);
    return this.http.post<Object>(url, {
      'name': data.name,
      'company_name': data.company_name,
      'description': data.description,
      'contact_person': data.contact_person,
      'email': data.email,
      'phone_number': data.phone_number,
      'is_active': 0
    }, {params});
  }
  
  
  
  public getTaxAdvisorsManagersList(): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public getSingleTaxAdvisorManagerData(manager_id): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('manager_id', manager_id);
    return this.http.get<Object>(url, {params});
  }
  
  public getSingleTaxAdvisorManagersList(data): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('tax_adviser_id', data.id);
    return this.http.get<Object>(url, {params});
  }
  
  public createTaxAdvisorsManager(data, tax_adviser_id): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post<Object>(url, {
      'name': data.name,
      'email': data.email,
      'password': data.password,
      'tax_adviser_id': tax_adviser_id,
      'phone_number': data.phone_number,
      'is_active': 1
    }, {params});
  }
  
  public editTaxAdvisorsManager(data, manager_id, tax_adviser_id, user_status): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('manager_id', manager_id);
    return this.http.post<Object>(url, {
      'name': data.name,
      'email': data.email,
      'password': data.password /*(data.password.length <= 0) ? null : data.password*/,
      'tax_adviser_id': tax_adviser_id,
      'phone_number': data.phone_number,
      'is_active': user_status
    }, {params});
  }
  
  public deleteTaxAdvisorManager(i): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('manager_id', i.id);
    return this.http.post<Object>(url, {
      'is_active': !i.is_active,
      'tax_adviser_id': i.tax_advisers_id,
      'name': i.name,
      'email': i.email,
      'phone_number': i.phone_number
    }, {params});
  }
  
  public assignMobileUserToTaxAdvisorManager(i): Observable<any> {
    console.log(i);
    const url = `${this.env.apiBase}/taxadviser/manager/user`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('user_id', i.user_id)
      .append('manager_id', i.manager_id);
    return this.http.post<Object>(url, params);
  }
  
  public getTaxAdvisorManagerStatusForUser(i): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager/user/status`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('user_id', i.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public changeTaxAdvisorManagerStatusForUser(i): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager/user/status`;
    console.log(i);
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('progress_status', i.progress_status)
      .append('user_id', i.user_id);
    let headers = new HttpHeaders()
      .set('ignoreLoadingBar', '');
    return this.http.post<Object>(url, {},
      {params: params, headers: headers}
    );
  }
  
  public logTimeByTaxAdvisorManagerForUser(i): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager/user/status`;
    console.log(i);
    this.getUserData();
    let params;
    if (i.log_work_id === null) {
      params = new HttpParams()
        .append('userId', this.USER.user_id)
        .append('log_time', i.log_time)
        .append('log_date', i.log_date)
        .append('comment', i.comment)
        .append('user_id', i.user_id);
    } else {
      params = new HttpParams()
        .append('userId', this.USER.user_id)
        .append('log_time', i.log_time)
        .append('log_date', i.log_date)
        .append('comment', i.comment)
        .append('log_work_id', i.log_work_id)
        .append('user_id', i.user_id);
    }
    
    return this.http.post<Object>(url, params);
  }
  
  public getTaxAdvisorManagerLoggedTime(data): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager/time`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('manager_id', data.manager_id)
      .append('status', data.status)
      .append('date_start', data.date_start)
      .append('date_end', data.date_end);
    return this.http.get<Object>(url, {params});
  }
  
  public getActionLogsByManager(i): Observable<any> {
    const url = `${this.env.apiBase}/logs`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('per_page', i.per_page)
      .append('page', i.page)
      .append('sort', i.sort)
      .append('order_by', i.order_by)
      .append('manager_id', i.manager_id)
      .append('date_start', i.date_start)
      .append('date_end', i.date_end)
      .append('user_id', i.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public setupManagerWorkRate(data): Observable<any> {
    const url = `${this.env.apiBase}/taxadviser/manager`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('manager_id', data.id);
    return this.http.post<Object>(url, {
      'name': data.name,
      'email': data.email,
      'tax_adviser_id': data.tax_advisers_id,
      'phone_number': data.phone_number,
      'is_active': data.is_active,
      'manager_rate': data.rate
    }, {params});
  }
  
}
