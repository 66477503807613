import { Component, OnInit } from '@angular/core';
import { AlertService, TaxAdvisorsService } from "../_services/index";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { TabDirective } from 'ngx-bootstrap';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-taxadvisors',
  templateUrl: './taxadvisors.component.html',
  styleUrls: ['./taxadvisors.component.less']
})
export class TaxAdvisorsComponent implements OnInit {
  
  tab = 1;
  
  taxAdvisors: any[];
  taxAdvisorsManagers: any[];
  
  pConfig1 = {
    itemsPerPage: 50,
    currentPage: 1,
    sortField: '',
    reverseOrder: false,
    search: ''
  };
  pConfig2 = {
    itemsPerPage: 50,
    currentPage: 1,
    sortField: '',
    reverseOrder: false,
    search: ''
  };
  
  back_url: any = localStorage.getItem('back_url');
  
  constructor(
    private _alertService: AlertService,
    private router: Router,
    private _taxAdvisorsService: TaxAdvisorsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.taxAdvisorsList();
    this.taxAdvisorsManagersList();
  }
  
  tabHandler(pageId: number): void {
    this.tab = pageId;
  }
  
  sortBy1 = function(sortField) {
    this.pConfig1['reverseOrder'] = (this.pConfig1['sortField'] === sortField) ? !this.pConfig1['reverseOrder'] : false;
    this.pConfig1['sortField'] = sortField;
  };
  sortBy2 = function(sortField) {
    this.pConfig2['reverseOrder'] = (this.pConfig2['sortField'] === sortField) ? !this.pConfig2['reverseOrder'] : false;
    this.pConfig2['sortField'] = sortField;
  };
  pageChange1(event: any): void {
    this.pConfig1['currentPage'] = event;
  }
  pageChange2(event: any): void {
    this.pConfig2['currentPage'] = event;
  }
  
  getTaxAdvisorsList(): Observable<any> {
    return this._taxAdvisorsService.getTaxAdvisorsList();
  }
  getTaxAdvisorsManagersList(): Observable<any> {
    return this._taxAdvisorsService.getTaxAdvisorsManagersList();
  }
  
  taxAdvisorsList(): void {
    this.getTaxAdvisorsList()
      .subscribe(res => {
        this.taxAdvisors = res['tax_adviser'];
        localStorage.setItem('back_url', this.router.url);
      }, error => {
        console.log(error);
      });
  }
  taxAdvisorsManagersList(): void {
    this.getTaxAdvisorsManagersList()
      .subscribe(res => {
        this.taxAdvisorsManagers = res.manager;
        this.taxAdvisorsManagers.forEach(key => {
          if (key.phone_number === null) {
            key.phone_number = '';
          }
        });
      }, error => {
        console.log(error);
      });
  }
  
  public goToSingleAdvisorsPage(i): void {
    this.router.navigate([`taxadvisors/${i.id}`]);
  }
  public editTaxAdvisor(i): void {
    this.router.navigate([`taxadvisors/${i.id}/edit`]);
  }
  public deleteTaxAdvisor(i): void {
    let translated_msg;
    this.translate.get('Alert.Action_of_deletion_is_irreversible')
      .subscribe((res: Object) => {
        translated_msg = res;
        let q = confirm(translated_msg);
        if (q) {
          this._taxAdvisorsService.deleteTaxAdvisor(i)
            .subscribe(response => {
              this._alertService.success('Alert.Tax_advisor_deletion_success');
              this.taxAdvisorsList();
            }, err => {
              console.log(err);
            });
        }
      });
  }
  
  public goToSingleTaxAdvisorsManagerPage(i): void {
    this.router.navigate([`taxadvisors/${i.tax_advisers_id}/manager/${i.id}`]);
  }
  public deleteTaxAdvisorManager(i): void {
    let translated_msg;
    if (i.is_active) {
      this.translate.get('Alert.Are_you_sure_you_want_to_deactivate_specialist')
        .subscribe((res: Object) => {
          translated_msg = res;
          let q = confirm(translated_msg);
          if (q) {
            this._taxAdvisorsService.deleteTaxAdvisorManager(i)
              .subscribe(response => {
                this.taxAdvisorsManagersList();
              }, err => {
                console.log(err);
              });
          }
        });
    } else {
      this.translate.get('Alert.Are_you_sure_you_want_to_activate_specialist')
        .subscribe((res: Object) => {
          translated_msg = res;
          let q = confirm(translated_msg);
          if (q) {
            this._taxAdvisorsService.deleteTaxAdvisorManager(i)
              .subscribe(response => {
                this.taxAdvisorsManagersList();
              }, err => {
                console.log(err);
              });
          }
        });
    }
  }
  public editTaxAdvisorsManager(data): void {
    this.router.navigate([`taxadvisors/${data.tax_advisers_id}/manager/${data.id}/edit`]);
  }
  
  public onSelect(e: TabDirective): void {
    console.log(e);
    (e.id === 'tab1') ? this.taxAdvisorsList() : this.taxAdvisorsManagersList();
  }
  
}
