// INTERNAL MODULES
import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from "@angular/common/http";
import {RouterModule} from "@angular/router";

// MAIN MODULES
import {AppRoutingModule} from "./app.routing";
import {AppComponent} from "./app.component";
import {SharedLazyModule} from "./shared/modules/shared-lazy.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

// SERVICES
import {
	EnvServiceProvider,
	CategoryService,
	AlertService,
	AuthenticationService,
	UserCreationService,
	TaxAdvisorsService,
	SharedService,
	SettingsService,
	MobileUsersService,
	DecisionTreeService
} from "./_services/index";

// GUARDS
import {AuthGuard} from "./_guards/index";

// INTERCEPTORS
import {JwtInterceptor} from "./_helpers/index";

// EXTERNAL MODULES
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader/";

// COMPONENTS
import {HeaderComponent} from "./shared/components/header/header.component";
import {LoginComponent} from "./login/login.component";
import {CategoriesListComponent} from "./categories/categories.component";
import {CreateCategoryComponent} from "./categories/create/create-category.component";
import {EditCategoryComponent} from "./categories/edit/edit-category.component";
import {EditSubcategoryComponent} from "./categories/edit/edit-subcategory.component";
import {CreateSubcategoryComponent} from "./categories/create/create-subcategory.component";
import {FooterComponent} from "./shared/components/footer/footer.component";
import {TaxAdvisorsComponent} from "./taxadvisors/taxadvisors.component";
import {SingleTaxAdvisorComponent} from "./taxadvisors/single-taxadvisor/single-taxadvisor.component";
import {CreateTaxAdvisorComponent} from "./taxadvisors/create/create-taxadvisor.component";
import {CreateTaxAdvisorsManagerComponent} from "./taxadvisors/create/create-taxadvisors-manager.component";
import {EditTaxAdvisorComponent} from "./taxadvisors/edit/edit-taxadvisor.component";
import {EditTaxAdvisorsManagerComponent} from "./taxadvisors/edit/edit-taxadvisors-manager.component";
import {SettingsComponent} from "./settings/settings.component";
import {LanguageComponent} from "./settings/language/language.component";
import {DecisionTreeComponent} from "./decision-tree/decision-tree.component";
import {DecisionTreeVisualizationComponent} from "./decision-tree/decision-tree-visualization/decision-tree-visualization.component";
import {NodeWrapComponent} from "./decision-tree/decision-tree-visualization/node-wrap/node-wrap.component";
import {NodeComponent} from "./decision-tree/decision-tree-visualization/node/node.component";
import {DecisionTreeTableComponent} from "./decision-tree/decision-tree-table/decision-tree-table.component";
import {UserAttributesComponent} from "./settings/user-attributes/user-attributes.component";
import {SingleTaxadvisorsManagerComponent} from "./taxadvisors/single-taxadvisors-manager/single-taxadvisors-manager.component";
import {FaqComponent} from "./settings/faq/faq.component";
import {CreateQuestionnaireComponent} from "./categories/questionnaire/create/create-questionnaire.component";
import {EditQuestionnaireComponent} from "./categories/questionnaire/edit/edit-questionnaire.component";
import {CreateUserAttributeComponent} from "./settings/user-attributes/create-user-attribute/create-user-attribute.component";
import {EditUserAttributeComponent} from "./settings/user-attributes/edit-user-attribute/edit-user-attribute.component";
import {
	BsDatepickerModule,
	BsDropdownModule,
	BsModalService,
	CarouselModule,
	ModalModule,
	TooltipModule,
	BsModalRef
} from "ngx-bootstrap";
import {EmailSetupComponent} from "./settings/email-setup/email-setup.component";


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		CategoriesListComponent,
		HeaderComponent,
		CreateCategoryComponent,
		EditCategoryComponent,
		EditSubcategoryComponent,
		LoginComponent,
		CreateSubcategoryComponent,
		FooterComponent,
		TaxAdvisorsComponent,
		SingleTaxAdvisorComponent,
		CreateTaxAdvisorComponent,
		CreateTaxAdvisorsManagerComponent,
		EditTaxAdvisorsManagerComponent,
		EditTaxAdvisorComponent,
		SettingsComponent,
		LanguageComponent,
		DecisionTreeComponent,
		DecisionTreeVisualizationComponent,
		NodeWrapComponent,
		NodeComponent,
		DecisionTreeTableComponent,
		UserAttributesComponent,
		CreateUserAttributeComponent,
		EditUserAttributeComponent,
		SingleTaxadvisorsManagerComponent,
		FaqComponent,
		CreateQuestionnaireComponent,
		EditQuestionnaireComponent,
		EmailSetupComponent
	],
	imports: [
		SharedLazyModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		RouterModule,
		LoadingBarHttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			isolate: true
		}),
		ModalModule.forRoot(),
		CarouselModule.forRoot(),
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		TooltipModule.forRoot()
	],
	providers: [
		EnvServiceProvider,
		AuthGuard,
		AuthenticationService,
		CategoryService,
		AlertService,
		UserCreationService,
		TaxAdvisorsService,
		SharedService,
		SettingsService,
		MobileUsersService,
		DecisionTreeService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		}
	],
	exports: [],
	bootstrap: [AppComponent]
})
export class AppModule {
}
